import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { WebshopMetrics as WebshopMetricsV1 } from './model/webshop-metrics.model';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';
import { WebshopMetricsAdapter } from './adapters/webshop-metrics.adapter';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';

@Injectable({
  providedIn: 'root',
})
export class WebshopMetricsService {
  private http = inject(HttpClient);

  private configStore = inject(ConfigStoreService);

  private _fetchWebshopMetrics(
    replacements: UrlReplacement[]
  ): Observable<WebshopMetricsV1[]> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'metrics',
      'v1',
      'webshopMetrics',
      replacements
    );

    return this.http.get<WebshopMetricsV1[]>(endpoint);
  }

  fetchWebshopMetrics(webshopUuid: string): Observable<WebshopMetrics> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._fetchWebshopMetrics(replacements).pipe(
      switchMap((data: WebshopMetricsV1[]) => {
        return of(new WebshopMetricsAdapter().transform(data));
      })
    );
  }
}
