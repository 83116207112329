export class SafeRedirects {
  private openTag: HTMLAnchorElement;

  safeRedirect(url: string) {
    this.openTag = document.createElement('a');
    this.openTag.rel = 'noopener';
    this.openTag.href = url;
    this.openTag.click();
  }

  safeNewTabRedirect(url: string) {
    this.openTag = document.createElement('a');
    this.openTag.rel = 'noopener';
    this.openTag.href = url;
    this.openTag.target = '_blank';
    this.openTag.click();
  }
}
