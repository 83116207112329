import { Selector } from '@ngxs/store';
import { TodoListV2State, TodoListV2StateModel } from './todo-list-v2.state';

import { DeliveriesOverviewSummary } from 'src/app/shared/models/delivery/v2/deliveries-v2.model';

export class DeliveriesTodoQueries {
  @Selector([TodoListV2State])
  static issuesLoading(state: TodoListV2StateModel): boolean {
    return state.deliveriesIssues.loading;
  }

  @Selector([TodoListV2State])
  static issuesData(state: TodoListV2StateModel): DeliveriesOverviewSummary[] {
    return state.deliveriesIssues.data;
  }

  @Selector([TodoListV2State])
  static issuesDataAvailable(state: TodoListV2StateModel): boolean {
    return !!state.deliveriesIssues.data.length;
  }
}
