import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
// Import the module from the SDK
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { PendingChangesGuard } from './core/guards/unsaved-changes.guard';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { AppRoutingModule } from './core/modules/app-routing/app-routing.module';
import { MaterialModule } from './core/modules/material/material.module';
import { AccountSettingsState } from './core/states/account-settings.state';
import { AuthenticationState } from './core/states/authentication.state';
import { ConfigState } from './core/states/config.state';
import { LocaleState } from './core/states/locale.state';
import { NavigationState } from './core/states/navigation.state';
import { WebshopState } from './core/states/webshop.state';
import { PageNotFoundModule } from './features/page-not-found/page-not-found.module';
import { SsoModule } from './features/sso/sso.module';
import { WebshopsModule } from './features/webshops/webshops.module';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
} from '@angular/material/core';
import { APP_DATE_FORMATS } from './core/constants/date-formats.constants';
import { AppState } from './core/states/app.state';
import { AccountState } from './core/states/account.state';
import { NgxsWebSocketPluginModule } from '@ngxs/websocket-plugin';
import {
  TOOLTIP_DEFAULT_OPTIONS,
  tooltipDefaults,
} from './shared/components/design-system/tooltip/model/tooltip.model';
import { RouterModule } from '@angular/router';
import { HomeModule } from './features/home/home.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { ToastModule } from './shared/components/design-system/toast/toast.module';
import { DialogModule } from '@angular/cdk/dialog';
import { UsecsvAngularPluginModule } from '@usecsv/angular';
import { ProgressBarModule } from './shared/components/design-system/progress-bar/progress-bar.module';
import { CustomIconRegistryModule } from './core/modules/material/icon-registry.module';
import { ConfirmationState } from './shared/components/confirmation-dialog/state/confirmation.state';
import { PendingChangesState } from './core/states/pending-changes.state';
import { BuyOrderSettingsState } from './core/states/buyorder-settings.state';
import { ImportsModule } from './features/imports/imports.module';
import { AccountPermissionsState } from './core/states/account-permissions.state';
import { NavMenuV2State } from './features/home/state/nav-menu-v2.state';
import { AnalyticsModule } from './features/analytics/analytics.module';
import { StatusPageState } from './features/home/state/status-page.state';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    NgxsModule.forRoot(
      [
        AppState,
        WebshopState,
        NavigationState,
        AuthenticationState,
        ConfigState,
        AccountSettingsState,
        AccountState,
        AccountPermissionsState,
        LocaleState,
        StatusPageState,
        NavMenuV2State,
        ConfirmationState,
        PendingChangesState,
        BuyOrderSettingsState,
      ],
      { developmentMode: !environment.production }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'optiply-store',
      disabled: environment.production,
    }),
    NgxsWebSocketPluginModule.forRoot({}),
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    RouterModule,
    MaterialModule,
    CustomIconRegistryModule,
    AppRoutingModule,
    SsoModule,
    PageNotFoundModule,
    WebshopsModule,
    HomeModule,
    DashboardModule,
    AnalyticsModule,
    ToastModule,
    DialogModule,
    UsecsvAngularPluginModule,
    ProgressBarModule,
    ImportsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PendingChangesGuard,
    MatIconRegistry,
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'MMM d, y' },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor() {
    //intentiona
  }
}
