import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import {
  RefreshStatusIncidents,
  StatusIncidents,
} from '../actions/status-page.actions';
import { Observable, catchError, concatMap, delay, tap } from 'rxjs';
import {
  Incident,
  Incidents,
} from '../components/toolbar/components/status-page/model/status-page.model';
import { StatusPageService } from 'src/app/core/api/status-page/status-page.service';

export interface StatusPageStateModel {
  incidents: Incident[];
  iconColor: string;
  statusDisabled: boolean;
}

// Define your initial state here
@State<StatusPageStateModel>({
  name: 'statusPageState',
  defaults: {
    incidents: [],
    iconColor: null,
    statusDisabled: false,
  },
})
@Injectable()
export class StatusPageState {
  private statusPageService = inject(StatusPageService);

  @Selector()
  static incidents(state: StatusPageStateModel): Incident[] {
    return state.incidents;
  }

  @Selector()
  static iconColor(state: StatusPageStateModel): string {
    return state.iconColor;
  }

  @Selector()
  static statusDisabled(state: StatusPageStateModel): boolean {
    return state.statusDisabled;
  }

  @Action(StatusIncidents, { cancelUncompleted: true })
  statusIncidents(ctx: StateContext<StatusPageStateModel>) {
    return this._findStatusIncidents(ctx).pipe(
      tap((incidents: Incidents) => {
        ctx.patchState({
          incidents: incidents.incidents,
          iconColor: incidents.iconColor,
          statusDisabled: false,
        });
      })
    );
  }

  @Action(RefreshStatusIncidents, {
    cancelUncompleted: true,
  })
  refreshStatusIncidents(ctx: StateContext<StatusPageStateModel>) {
    return ctx.dispatch(new StatusIncidents()).pipe(
      delay(300000),
      concatMap(() => ctx.dispatch(new RefreshStatusIncidents())),
      catchError(e => {
        ctx.dispatch(new RefreshStatusIncidents());
        throw new Error(e.message || e);
      })
    );
  }

  private _findStatusIncidents(
    ctx: StateContext<StatusPageStateModel>
  ): Observable<Incidents | void> {
    return this.statusPageService.findStatusIncidents().pipe(
      catchError(e => {
        ctx.patchState({
          incidents: [],
          statusDisabled: true,
        });

        throw new Error(e.message || e);
      })
    );
  }
}
