<button
  data-intercom-target="0558a3c2-2140-4ffb-a64d-dce7e445c73e"
  aria-label="Main header change language button"
  [cdkMenuTriggerFor]="optionsMenu"
  appTooltip="Change language"
  i18n-appTooltip="language icon tooltip"
  appButton
  appButtonIcon
  variant="link"
  transparent
  class="toolbar-button">
  <mat-icon
    [svgIcon]="currentLocale?.flag ? currentLocale.flag : 'language'"
    class="toolbar-icon flag">
  </mat-icon>
</button>

<ng-template #optionsMenu>
  <app-menu>
    <button
      *ngFor="let item of locales"
      aria-label="Main header language button"
      appMenuItem
      (click)="onLocaleSelected(item)"
      [class.selected-language]="currentLocale.value === item.value">
      <mat-icon
        [svgIcon]="item && item?.flag ? item.flag : 'language'"
        class="toolbar-icon"></mat-icon>
      <span>{{ item.display }}</span>
    </button>
  </app-menu>
</ng-template>
