import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  StockLevelHistory,
  TimeSeriesData,
} from 'src/app/shared/models/stock-level/history.model';
import { DatePipe } from '@angular/common';

import {
  HistoryData,
  StockLevelHistory as StockLevelHistoryV1,
  TimeSeriesRow,
} from './model/stock-history.model';

export class StockHistoryAdapter
  implements DataAdapter<StockLevelHistoryV1, StockLevelHistory>
{
  transform(data: StockLevelHistoryV1): StockLevelHistory {
    const history: StockLevelHistory = { labels: [], data: [] };
    var datePipe = new DatePipe('en-US');

    history.data = data[0].historyData.map((category: HistoryData) => {
      if (category === null) {
        return null;
      }
      const newCategory: TimeSeriesData = {
        values: [],
        name: category.productCategory,
      };

      newCategory.values = category.timeSeries.map((row: TimeSeriesRow) => {
        return row.serviceLevel;
      });

      return newCategory;
    });

    data[0].historyData[0]?.timeSeries.map((row: TimeSeriesRow) => {
      history.labels.push(datePipe.transform(row.date, 'MMM d, y'));
    });

    return history;
  }
}
