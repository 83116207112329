import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoadConfig } from '../actions/load-config.action';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Config resolver to be used on the routing declaration.
 * This resolver will load the configuration for the front-end before the user
 * can iteract with it.
 * Check app-routing module to see where it is used.
 */
@Injectable()
export class ConfigResolver {
  constructor(private store: Store) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    // this action will wait for the inner async call to be resolved
    return this.store.dispatch(new LoadConfig());
  }
}
