import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

import { WebshopTabAllComponent } from './components/webshop-tab-all/webshop-tab-all.component';
import { WebshopsDialogComponent } from './components/webshops-dialog/webshops-dialog.component';
import { WebshopsComponent } from './webshops.component';
import { DataTableV2Module } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.module';
import { DialogContainerModule } from 'src/app/shared/components/dialog-container/dialog-container.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogModule } from '@angular/cdk/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'src/app/shared/components/design-system/button/button.module';

@NgModule({
  declarations: [
    WebshopsComponent,
    WebshopsDialogComponent,
    WebshopTabAllComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    SharedModule,
    DialogContainerModule,
    OverlayModule,
    DialogModule,
    DataTableV2Module,
    ButtonModule,
  ],
})
export class WebshopsModule {}
