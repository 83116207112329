import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';

import { LoadWebshops, WebshopSelected } from '../actions/webshop.action';
import { WebshopState } from '../states/webshop.state';

/**
 * Config resolver to be used on the routing declaration.
 * This resolver will load the configuration for the front-end before the user
 * can iteract with it.
 * Check app-routing module to see where it is used.
 */
@Injectable()
export class WebshopResolver {
  private store = inject(Store);

  private router = inject(Router);

  private _checkForValidWebshop(): MonoTypeOperatorFunction<Webshop> {
    return tap((webshop: Webshop) => {
      if (webshop === null) {
        this.router.navigate(['webshops'], {
          queryParams: { skipAutoSelection: true },
        });
      }
    });
  }

  private _fetchWebshopsPipe(webshopId: string): Observable<Webshop> {
    return this.store.dispatch(new LoadWebshops()).pipe(
      map(() => this.store.selectSnapshot(WebshopState.webshops)),
      map((webshops: Webshop[]) => webshops),
      this._selectWebshopPipe(webshopId)
    );
  }

  private _selectWebshopPipe(
    webshopId: string
  ): OperatorFunction<Webshop[], Webshop> {
    return map((webshops: Webshop[]) => {
      const filteredWebshops: Webshop[] = webshops.filter(
        (webshop: Webshop) => webshop.uuid === webshopId
      );

      if (filteredWebshops.length === 1) {
        this.store.dispatch(new WebshopSelected(filteredWebshops[0]));
        return filteredWebshops[0];
      }

      return null;
    });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    // this action will wait for the inner async call to be resolved
    const webshopId: string = route.paramMap.get('webshopId');

    if (webshopId === null) {
      return of(null);
    }

    const snapshot: Webshop[] = this.store.selectSnapshot(
      WebshopState.webshops
    );
    if (snapshot.length > 0) {
      return of(snapshot).pipe(
        this._selectWebshopPipe(webshopId),
        this._checkForValidWebshop()
      );
    }

    return this._fetchWebshopsPipe(webshopId).pipe(
      this._checkForValidWebshop()
    );
  }
}
