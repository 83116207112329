import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { select, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AccountState } from 'src/app/core/states/account.state';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { NavMenuV2State } from './state/nav-menu-v2.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppState } from 'src/app/core/states/app.state';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  @Select(NavMenuV2State.expanded)
  expanded$: Observable<boolean>;

  @Select(NavMenuV2State.closed)
  closed$: Observable<boolean>;

  private store = inject(Store);

  private account = toSignal(this.store.select(AuthenticationState.account));

  private webshop = toSignal(this.store.select(WebshopState.selected));

  private intercomData = toSignal(this.store.select(AccountState.intercomData));

  private theme = select(AccountSettingsState.colorThemeLowercase);

  ngOnInit(): void {
    window.Intercom('boot', {
      app_id: 'e8b6t2nc',
      user_id: this.account().email,
      user_hash: this.intercomData().userHash,
      company: {
        id: this.webshop().handle,
        name: this.webshop().handle,
      },
    });

    Canny('initChangelog', {
      appID: '621f5626d4c36338280e3bee',
      position: 'bottom',
      align: 'right',
      theme: this.theme(),
    });
  }
}
