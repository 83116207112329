<div class="toolbar-container">
  <div class="toolbar-row">
    <button
      aria-label="Main header toggle menu button"
      (click)="onNavMenuVisibilityChange()"
      appTooltip="Toggle menu visibility"
      i18n-appTooltip="toolbar menu visibility"
      appButton
      appButtonIcon
      variant="link"
      transparent
      class="toolbar-button">
      <mat-icon
        svgIcon="list-vertical"
        class="toolbar-icon"
        i18n="toolbar menu button"
        >menu</mat-icon
      >
    </button>
    <div class="logo-container">
      <a routerLink="home">
        <img
          *ngIf="!betaLogo"
          class="logo"
          src="assets/images/optiply.svg"
          alt="Optiply logo" />
        <img
          *ngIf="betaLogo"
          class="logo beta"
          src="assets/images/optiply_beta.svg"
          alt="Optiply beta logo" />
      </a>
    </div>

    <app-webshop-selector-v2 />

    <div class="spacer"></div>

    <div class="right-container">
      <app-status-page></app-status-page>

      <button
        data-intercom-target="995a8ed3-abcd-4734-8850-70d9334ea13e"
        aria-label="Main header latest updates button"
        data-canny-changelog
        appTooltip="Latest Updates"
        i18n-appTooltip="account settings menu canny changelog"
        appButton
        appButtonIcon
        variant="link"
        transparent
        class="toolbar-button">
        <mat-icon svgIcon="bookmark-star" class="toolbar-icon"></mat-icon>
      </button>

      <app-toolbar-help></app-toolbar-help>

      <app-languages [shouldSaveSettings]="false"> </app-languages>

      <div class="vertical-separator"></div>

      <app-account></app-account>
    </div>
  </div>
</div>
