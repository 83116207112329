import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { STRINGS } from '../../model/todo-list-v2.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { Select, Store } from '@ngxs/store';
import {
  AddFilterParamNoReload,
  RemoveAllFilters,
} from 'src/app/features/purchase-v3/components/proposed-orders/actions/proposed-orders.actions';
import { PURCHASE_FILTERS } from 'src/app/features/purchase-v3/components/proposed-orders/model/data-table.model';
import { NavigateTo } from 'src/app/core/actions/navigation.action';
import { PurchaseTodoQueries } from '../../states/purchase-todo.queries';
import { Observable, delay, distinctUntilKeyChanged } from 'rxjs';
import { OrdersOverviewSummary } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import { LoadOrdersIssues } from '../../actions/todo-list-v2.actions';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-purchases-todo',
  templateUrl: './purchases-todo.component.html',
  styleUrl: './purchases-todo.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasesTodoComponent implements OnInit {
  @Select(PurchaseTodoQueries.issuesLoading)
  loading$: Observable<boolean>;

  @Select(PurchaseTodoQueries.issuesData)
  issues$: Observable<OrdersOverviewSummary[]>;

  @Select(PurchaseTodoQueries.issuesDataAvailable)
  issuesAvailable$: Observable<boolean>;

  readonly COLUMNS_STRINGS = STRINGS.columns.purchases;

  readonly TITLE = STRINGS.metadata.todoList.purchaseTitle;

  readonly COMMON_STRINGS = MESSAGES.common;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        distinctUntilKeyChanged('uuid'),
        delay(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new LoadOrdersIssues());
      });
  }

  goToPurchases(): void {
    this.store.dispatch(new NavigateTo(['purchase']));
  }

  filterPurchaseIssues(issue: string): void {
    this.store.dispatch([
      new RemoveAllFilters(),
      new AddFilterParamNoReload(PURCHASE_FILTERS[issue]),
      new NavigateTo(['purchase', 'proposed']),
    ]);
  }
}
