<button
  [attr.data-intercom-target]="option.intercomTarget"
  aria-label="Main menu button"
  class="nav-bar-menu-option"
  [routerLink]="option.route"
  routerLinkActive="nav-bar-menu-option-selected">
  <div
    class="icon-container"
    [appTooltip]="option.name"
    [tooltipDisabled]="tooltipDisabled"
    tooltipPosition="after">
    <mat-icon svgIcon="{{ option.icon }}" />
  </div>
  <span class="nav-bar-menu-option-name">{{ option.name }}</span>
</button>
