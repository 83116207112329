import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { UpdateColorTheme } from 'src/app/core/actions/settings.action';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ColorThemes } from 'src/app/shared/models/account/account-settings.model';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { STRINGS } from 'src/app/features/imports/model/imports.strings';
import { Account } from 'src/app/shared/models/account/account.model';
import { NavigateTo } from 'src/app/core/actions/navigation.action';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
  readonly STRINGS = STRINGS.metadata;
  webshop: Webshop = null;

  @Select(AuthenticationState.account)
  user$: Observable<Account>;

  @Select(AccountSettingsState.darkModeEnabled)
  darkModeEnabled$: Observable<boolean>;

  @Select(WebshopState.exportsEnabled)
  exportsEnabled$: Observable<boolean>;

  @Select(WebshopState.importsEnabled)
  importsEnabled$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private store: Store
  ) {}

  onLogoutClick(): void {
    this.authService.logout();
  }

  onAccountSettingsClick(): void {
    this.store.dispatch(new NavigateTo(['settings']));
  }

  onManageAccountClick(): void {
    this.store.dispatch(new NavigateTo(['account']));
  }

  /**
   * Navigates to the exports page
   */
  onExportsClick(): void {
    this.store.dispatch(new NavigateTo(['exports']));
  }

  onImportsClick(): void {
    this.store.dispatch(new NavigateTo(['imports']));
  }

  setColorTheme(isDarkTheme: boolean): void {
    const colorTheme = isDarkTheme ? ColorThemes.DARK : ColorThemes.LIGHT;

    this.store.dispatch(new UpdateColorTheme(colorTheme));
  }
}
