<button
  data-intercom-target="c3e8f76c-975b-4dbf-b947-3aa6c9b45894"
  aria-label="Main header help button"
  [cdkMenuTriggerFor]="optionsMenu"
  [appTooltip]="
    (statusDisabled$ | async)
      ? MESSAGES.disabledTooltip
      : (iconColor$ | async) === 'green'
        ? MESSAGES.systemOperational
        : MESSAGES.title
  "
  appButton
  appButtonIcon
  variant="link"
  transparent
  class="toolbar-button"
  [disabled]="statusDisabled$ | async">
  <mat-icon
    svgIcon="status-report"
    [class]="'icon-' + (iconColor$ | async)"></mat-icon>
</button>

<ng-template #optionsMenu>
  <app-status-page-dropdown></app-status-page-dropdown>
</ng-template>
