import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Actions,
  ofActionDispatched,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { AllowExit } from 'src/app/core/actions/app.action';
import { LocaleChanged } from 'src/app/core/actions/locale.action';
import { UpdateAccountLanguage } from 'src/app/core/actions/settings.action';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { LocaleState } from 'src/app/core/states/locale.state';
import { Locale } from 'src/app/shared/models/locale.model';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent implements OnInit, OnDestroy {
  @Input() shouldSaveSettings: boolean;
  /**
   * The current list of locales that were provided by the state store.*
   */
  currentLocale: Locale;
  locales: Locale[];

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions,
    private notificationCenter: NotificationCenterService
  ) {}

  ngOnInit(): void {
    this.store
      .select(LocaleState.locales)
      .pipe(
        tap(locales => {
          this.locales = locales;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.store
      .select(LocaleState.currentLocale)
      .pipe(takeUntil(this.destroy$))
      .subscribe(locale => {
        this.currentLocale = locale;
      });

    this.actions
      .pipe(ofActionDispatched(UpdateAccountLanguage), takeUntil(this.destroy$))
      .subscribe(() => {
        this.notificationCenter.showToast(
          MESSAGES.notifications.preferences.changingLanguage
        );
      });

    this.actions
      .pipe(
        ofActionSuccessful(UpdateAccountLanguage),
        delay(1500),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(new LocaleChanged(this.currentLocale.value));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onLocaleSelected(locale: Locale) {
    this.store.dispatch(new AllowExit());

    this.locales
      .filter(item => item.value === locale.value)
      .forEach(item => {
        this.store.dispatch(
          new UpdateAccountLanguage({ localeId: item.value })
        );
      });
  }
}
