import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appTodoIssueStatus]',
})
export class TodoIssueStatusDirective {
  @HostBinding('class')
  class: string = 'todo-issue-status';

  @Input({ required: true, alias: 'appTodoIssueStatus' })
  set status(value: 'red' | 'orange' | 'green') {
    this.class = `todo-issue-status todo-issue-status-${value}`;
  }
}
