import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-nav-menu-user-info',
  templateUrl: './nav-menu-user-info.component.html',
  styleUrls: ['./nav-menu-user-info.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuUserInfoComponent implements OnInit {
  @Input() username: string;

  @Input() name: string;

  ngOnInit(): void {
    //intended
  }
}
