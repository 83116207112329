import { Selector } from '@ngxs/store';
import { TodoListV2State, TodoListV2StateModel } from './todo-list-v2.state';
import { OrdersOverviewSummary } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';

export class PurchaseTodoQueries {
  @Selector([TodoListV2State])
  static issuesLoading(state: TodoListV2StateModel): boolean {
    return state.ordersIssues.loading;
  }

  @Selector([TodoListV2State])
  static issuesData(state: TodoListV2StateModel): OrdersOverviewSummary[] {
    return state.ordersIssues.data;
  }

  @Selector([TodoListV2State])
  static issuesDataAvailable(state: TodoListV2StateModel): boolean {
    return !!state.ordersIssues.data.length;
  }
}
