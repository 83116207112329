import { Injectable } from '@angular/core';

import * as echarts from 'echarts/lib/echarts';
import {
  ECHARTS_DARK_THEME,
  ECHARTS_LIGHT_THEME,
} from 'src/app/shared/models/echarts/themes.model';

@Injectable({
  providedIn: 'root',
})
export class EchartsService {
  constructor() {
    echarts.registerTheme('optiply-dark', ECHARTS_DARK_THEME);
    echarts.registerTheme('optiply-light', ECHARTS_LIGHT_THEME);
  }
}
