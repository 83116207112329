import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { Observable, filter } from 'rxjs';
import { LoadDashboardCache } from 'src/app/core/actions/dashboard-cache.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { STRINGS } from './model/analytics.strings';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent implements OnInit {
  readonly STRINGS = STRINGS.metadata;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  @Select(WebshopState.analyticsPageEnabled)
  analyticsPageEnabled$: Observable<boolean>;

  ngOnInit(): void {
    window.rudderanalytics.page('Analytics');

    this.store
      .select(WebshopState.selected)
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((webshop: Webshop) => {
        this.store.dispatch(new LoadDashboardCache(webshop.id));
      });
  }
}
