<div class="todo-list">
  @if ((hasPurchasePermission$ | async) || (hasDeliveriesPermission$ | async)) {
    <div class="todo-group-container">
      <h4 class="todo-group-title">Purchasing</h4>

      <div class="purchasing-layout">
        @if (hasPurchasePermission$ | async) {
          <app-purchases-todo />
        }

        @if (hasDeliveriesPermission$ | async) {
          <app-deliveries-todo />
        }
      </div>
    </div>
  }

  @if ((hasProductsPermission$ | async) || (hasSuppliersPermission$ | async)) {
    <div class="todo-group-container">
      <h4 class="todo-group-title">Data management</h4>

      <div class="data-management-layout">
        @if (hasProductsPermission$ | async) {
          <app-products-todo />
        }

        @if (hasSuppliersPermission$ | async) {
          <app-suppliers-todo />
        }
      </div>
    </div>
  }
</div>
