import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { DashboardDetails } from 'src/app/shared/models/stock-level/dashboard-cache.model';

import { DashboardCacheAdapter } from './dashboard-cache.adapter';
import { DashboardCache as DashboardCacheV1 } from './model/dashboard-cache.model';

@Injectable({ providedIn: 'root' })
export class DashboardCacheService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private fetch(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshopLegacy',
      'v1',
      'dashboardCache',
      replacements
    );

    return this.http.get<DashboardCacheV1>(endpoint);
  }

  fetchCache(webshopId: number): Observable<DashboardDetails> {
    const replacements: UrlReplacement[] = [{ name: 'id', value: webshopId }];
    return this.fetch(replacements).pipe(
      catchError(() => {
        return of([]);
      }),
      switchMap(data => {
        return of(new DashboardCacheAdapter().transform(data));
      })
    );
  }
}
