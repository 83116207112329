import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LocaleService } from 'src/app/core/services/locale.service';
import { LocaleState } from 'src/app/core/states/locale.state';
import { Locale } from 'src/app/shared/models/locale.model';
import { ToggleMenuVisilibity } from '../../actions/nav-menu-v2.actions';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  betaLogo: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.store
      .select(LocaleState.locales)
      .pipe(
        takeUntil(this.destroy$),
        tap(locales => {
          this.checkLocaleBeta(locales, this.localeService.locale);
        })
      )
      .subscribe();
  }

  onNavMenuVisibilityChange() {
    this.store.dispatch(new ToggleMenuVisilibity());
  }

  checkLocaleBeta(locales: Locale[], localeId: string) {
    if (locales && localeId) {
      const matchedLocale = locales.filter(locale => locale.value === localeId);

      if (Array.isArray(matchedLocale) && matchedLocale.length === 0) {
        this.betaLogo = true;
        return;
      }
      matchedLocale.forEach(locale => {
        this.betaLogo = locale.beta;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
