<app-dialog-container
  ><div appDialogHeader class="header">
    <mat-icon svgIcon="shopping-basket" class="shop-icon"></mat-icon>
    <h2 appDialogTitle i18n="webshop dialog webshop selection title">
      Webshop Selection
    </h2>
  </div>
  <div appDialogBody class="custom-dialog-body">
    <div class="webshops-container">
      <app-webshop-tab-all
        (selectedWebshopChanged)="
          selectedWebshopChanged($event)
        "></app-webshop-tab-all>
    </div>
  </div>
  <div appDialogActions class="actions">
    <button
      aria-label="Webshop dialog logout button"
      appButton
      variant="tertiary"
      (click)="onLogoutClicked($event)"
      i18n="webshop dialog logout button name">
      Logout
    </button>
    <div fxFlex></div>
    <button
      aria-label="Webshop dialog next button"
      appButton
      variant="primary"
      [disabled]="!webshopSelected"
      (click)="onNextClicked($event)"
      i18n="webshop dialog next button name">
      Next
    </button>
  </div>
</app-dialog-container>
