import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { Observable, distinctUntilKeyChanged } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { RefreshStatusIncidents } from 'src/app/features/home/actions/status-page.actions';
import { StatusPageState } from 'src/app/features/home/state/status-page.state';

@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrl: './status-page.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPageComponent implements OnInit {
  MESSAGES = MESSAGES.common.statusPage;

  @Select(StatusPageState.iconColor)
  iconColor$: Observable<string>;

  @Select(StatusPageState.statusDisabled)
  statusDisabled$: Observable<boolean>;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        distinctUntilKeyChanged('uuid'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new RefreshStatusIncidents());
      });
  }
}
