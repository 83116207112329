<div class="todo-wrapper">
  <div class="todo-header">
    <div class="todo-label-wrapper">
      <mat-icon appTodoIcon [svgIcon]="todoIcon" />
      <h5 appTodoLabel>{{ todoLabel }}</h5>
    </div>

    <ng-content select="[todoAction]" />
  </div>

  <div class="todo-content">
    <ng-content />
  </div>
</div>
