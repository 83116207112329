import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-auth0-login',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Auth0LoginComponent implements OnInit {
  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.auth.login();
  }
}
