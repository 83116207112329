<app-todo-wrapper todoIcon="truck" [todoLabel]="TITLE">
  <button appButton variant="secondary" todoAction (click)="goToDeliveries()">
    {{ COMMON_STRINGS.goTo }} {{ TITLE | lowercase }}
  </button>

  @if (loading$ | async) {
    <app-todo-loading />
  } @else if ((issuesAvailable$ | async) === false) {
    <app-todo-no-issues />
  } @else {
    <app-todo-data-table>
      <table>
        <thead>
          <tr>
            <th>{{ COLUMNS_STRINGS.STATUS }}</th>
            <th>{{ COLUMNS_STRINGS.DESCRIPTION }}</th>
            <th class="align-right">{{ COLUMNS_STRINGS.TOTAL_ORDERED }}</th>
            <th class="align-right">{{ COLUMNS_STRINGS.TOTAL_DELIVERED }}</th>
            <th class="align-right">{{ COLUMNS_STRINGS.TOTAL_VALUE }}</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (issue of issues$ | async; track issue.status) {
            <tr>
              <td [appTodoIssueStatus]="issue.color">{{ issue.label }}</td>
              <td>
                <strong>{{ issue.subject }}</strong> {{ issue.description }}
              </td>
              <td class="align-right">{{ issue.totalOrdered }}</td>
              <td class="align-right">{{ issue.totalDelivered }}</td>
              <td class="align-right">
                {{ issue.totalValue | customCurrency }}
              </td>
              <td class="align-right fit-to-value">
                <button
                  appButton
                  variant="link"
                  size="sm"
                  (click)="filterDeliveriesIssues(issue.status)">
                  {{ COMMON_STRINGS.view }}
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </app-todo-data-table>
  }
</app-todo-wrapper>
