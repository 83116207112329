import { Component } from '@angular/core';
/**
 * Single Sign On route component.
 * This component is displayed when the user is not properly logged in.
 */
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.sass'],
})
export class SsoComponent {}
