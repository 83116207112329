import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  ActivationEnd,
  ActivationStart,
  Router,
  NavigationEnd,
  NavigationCancel,
} from '@angular/router';
import { Store } from '@ngxs/store';

import {
  NavigationChanged,
  NavigationEnded,
  NavigationStarted,
} from '../actions/navigation.action';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private location: Location
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        this.store.dispatch(new NavigationStarted());
      }

      if (event instanceof ActivationEnd || event instanceof NavigationCancel) {
        this.store.dispatch(new NavigationEnded());
      }

      if (event instanceof ActivationEnd) {
        if (event.snapshot.data.routerName !== undefined) {
          this.store.dispatch(
            new NavigationChanged({ path: event.snapshot.data.routerName })
          );
        }
      }

      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
