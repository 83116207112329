<button
  data-intercom-target="c3e8f76c-975b-4dbf-b947-3aa6c9b45894"
  aria-label="Main header help button"
  [cdkMenuTriggerFor]="optionsMenu"
  appTooltip="Help"
  i18n-appTooltip="toolbar help button"
  appButton
  appButtonIcon
  variant="link"
  transparent
  class="toolbar-button">
  <mat-icon svgIcon="questionmark-rounded" class="toolbar-icon"></mat-icon>
</button>

<ng-template #optionsMenu>
  <app-menu>
    <button
      data-intercom-target="7b896e5e-eb23-44d7-9e0d-8898ef77f21d"
      aria-label="Main header help help center button"
      appMenuItem
      (click)="onHelpClicked()">
      <div class="title-container">
        <mat-icon
          svgIcon="questionmark-rounded"
          class="toolbar-icon"></mat-icon>
        <span i18n="help settings menu help redirect">Help Center</span>
      </div>

      <span appMenuItemShortcut>
        <mat-icon
          svgIcon="external-link-xs"
          class="menu-icon external"></mat-icon>
      </span>
    </button>

    <button
      data-intercom-target="dfd061eb-1520-44df-bd3a-5a51206c9b26"
      aria-label="Main header help submit feedback button"
      appMenuItem
      (click)="redirectToFeedbackPortal()">
      <div class="title-container">
        <mat-icon svgIcon="chat-feedback" class="menu-icon"></mat-icon>
        <span i18n="help settings menu feedback portal">Submit Feedback</span>
      </div>

      <span appMenuItemShortcut>
        <mat-icon
          svgIcon="external-link-xs"
          class="menu-icon external"></mat-icon>
      </span>
    </button>

    <button
      data-intercom-target="593c22d0-4e65-467d-af26-1d07efbb42d5"
      aria-label="Main header help changelog button"
      appMenuItem
      (click)="onCannyClicked()">
      <div class="title-container">
        <mat-icon svgIcon="bookmark-star" class="menu-icon"></mat-icon>
        <span i18n="help settings menu canny redirect">Changelog</span>
      </div>

      <span appMenuItemShortcut>
        <mat-icon
          svgIcon="external-link-xs"
          class="menu-icon external"></mat-icon>
      </span>
    </button>

    <button
      data-intercom-target="a69ddee2-cde8-46c3-97cf-2c51f63e7d3d"
      aria-label="Main header help chat with us button"
      appMenuItem
      (click)="openIntercom()">
      <div class="title-container">
        <mat-icon svgIcon="chat-support" class="menu-icon"></mat-icon>
        <span i18n="toggle chat tooltip">Toggle Chat</span>
      </div>
    </button>
  </app-menu>
</ng-template>
