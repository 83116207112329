import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { AccessTokens } from '../vizzly-dashboard.service';

export class VizzlyDashboardAdapter
  implements DataAdapter<AccessTokens, AccessTokens>
{
  transform(data: AccessTokens): AccessTokens {
    return {
      dataAccessToken: data.dataAccessToken,
      dashboardAccessToken: data.dashboardAccessToken,
    };
  }
}
