import { Selector } from '@ngxs/store';
import { NavMenuV2State } from './nav-menu-v2.state';
import { MenuOption } from '../components/nav-menu-v2/model/nav-menu-v2.model';

export class NavMenuV2StateQueries {
  @Selector([NavMenuV2State.menuOptions])
  static primaryOptions(options: MenuOption[]): MenuOption[] {
    return options.filter(option => option.primary && option.enabled);
  }

  @Selector([NavMenuV2State.menuOptions])
  static secondaryOptions(options: MenuOption[]): MenuOption[] {
    return options.filter(option => !option.primary && option.enabled);
  }
}
