import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { IssueStatusColors } from '../../model/todo-list-v2.model';

@Component({
  selector: 'app-todo-issue-icon',
  templateUrl: './todo-issue-icon.component.html',
  styleUrl: './todo-issue-icon.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoIssueIconComponent {
  icon = input.required<string>();

  status = input.required<IssueStatusColors>();

  containerClasses = computed(() => ['container', `todo-${this.status()}`]);
}
