<app-basic-filter
  i18n-placeholder="webshop tab all webshop filter placeholder"
  placeholder="Filter webshops"
  (filteredText)="onWebshopFilter($event)"></app-basic-filter>
<app-webshop-data-table
  class="with-width"
  [dataSource]="dataSourceSubject$"
  [loading]="loadingSubject$"
  [filter]="filterSubject$"
  (selectedWebshopChanged)="selectedWebshop($event)">
</app-webshop-data-table>
