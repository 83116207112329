import {
  DARK_THEME_PALLETE,
  LIGHT_THEME_PALLETE,
} from 'src/app/core/constants/themes-pallete.constants';

export interface ColorsListV2 {
  hex: string;
  rgb: string;
}

export const ECHARTS_DARK_THEME = {
  darkMode: false,
  color: ['#4AB573', '#659EF2', '#E56262'], // For lines, bars, whatever...
  backgroundColor: 'rgba(0,0,0,0)',
  title: {
    textStyle: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
      color: DARK_THEME_PALLETE.ink_2,
    },
    subtextStyle: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 'normal',
      color: DARK_THEME_PALLETE.ink_2,
    },
  },
  axisPointer: {
    label: {
      color: DARK_THEME_PALLETE.ink_1,
    },
  },
  legend: {
    textStyle: {
      color: DARK_THEME_PALLETE.ink_1,
    },
  },
  textStyle: {
    color: DARK_THEME_PALLETE.ink_2,
    textBorderColor: 'transparent',
  },
  tooltip: {
    className: 'eChart-tooltip-dark',
    backgroundColor: DARK_THEME_PALLETE.shade_1,
    borderColor: DARK_THEME_PALLETE.shade_1,
    axisPointer: {
      type: 'line',
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_3,
        type: 'dashed',
      },
      crossStyle: {
        color: DARK_THEME_PALLETE.ink_3,
        type: 'dashed',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_3,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)',
      },
    },
    axisLabel: {
      show: true,
      color: DARK_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(255,255,255,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(255,255,255,0.1)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_3,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: 'rgba(255,255,255,0.1)',
      },
    },
    axisLabel: {
      show: true,
      color: DARK_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'line',
        color: 'rgba(255,255,255,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(255, 255, 255, 0.1)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_2,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: DARK_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_3,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: DARK_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: DARK_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  line: {
    smooth: true,
    symbol: 'circle',
    symbolSize: 1,
    itemStyle: {
      borderWidth: '1',
    },
  },
  markLine: {
    label: {
      color: DARK_THEME_PALLETE.ink_1,
    },
  },
};

export const ECHARTS_LIGHT_THEME = {
  darkMode: false,
  color: ['#4AB573', '#659EF2', '#E56262'],
  backgroundColor: 'rgba(0,0,0,0)',
  title: {
    textStyle: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
      color: LIGHT_THEME_PALLETE.ink_2,
    },
    subtextStyle: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 'normal',
      color: LIGHT_THEME_PALLETE.ink_2,
    },
  },
  axisPointer: {
    label: {
      color: LIGHT_THEME_PALLETE.shade_1,
    },
  },
  legend: {
    textStyle: {
      color: LIGHT_THEME_PALLETE.ink_1,
    },
  },
  textStyle: {
    color: LIGHT_THEME_PALLETE.ink_2,
    textBorderColor: 'transparent',
  },
  tooltip: {
    className: 'eChart-tooltip-dark',
    backgroundColor: LIGHT_THEME_PALLETE.shade_1,
    borderColor: LIGHT_THEME_PALLETE.shade_1,
    axisPointer: {
      type: 'line',
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_3,
        type: 'dashed',
      },
      crossStyle: {
        color: LIGHT_THEME_PALLETE.ink_3,
        type: 'dashed',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_2,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: LIGHT_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_2,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: LIGHT_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_2,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: LIGHT_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_2,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: LIGHT_THEME_PALLETE.ink_1,
      },
    },
    axisLabel: {
      show: true,
      color: LIGHT_THEME_PALLETE.ink_2,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'line',
        color: 'rgba(0,0,0,0.1)',
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(0,0,0,0.1)'],
      },
    },
  },
  line: {
    smooth: true,
    symbol: 'circle',
    symbolSize: 1,
    itemStyle: {
      borderWidth: '1',
    },
  },
  markLine: {
    label: {
      color: LIGHT_THEME_PALLETE.ink_1,
    },
  },
};
