<app-basic-context-nav-bar [titleName]="STRINGS.title" />

@if (analyticsPageEnabled$ | async) {
  <dashboard></dashboard>
} @else {
  <div class="dashboard-master-container">
    <div>
      <app-general-info></app-general-info>
      <app-service-level-chart-plugin
        class="service-level-chart"></app-service-level-chart-plugin>

      <app-service-level-info></app-service-level-info>
    </div>
  </div>
}
