import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { STRINGS } from '../../model/todo-list-v2.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { Select, Store } from '@ngxs/store';
import {
  AddFilterParamNoReload,
  RemoveAllFilters,
} from 'src/app/features/products-v2/actions/products-v2.actions';
import { PRODUCT_FILTERS } from 'src/app/features/products-v2/model/data-table.model';
import { NavigateTo } from 'src/app/core/actions/navigation.action';
import { Issue, IssueStatusColors } from '../../model/todo-list-v2.model';
import { LoadProductsIssues } from '../../actions/todo-list-v2.actions';
import { ProductsTodoQueries } from '../../states/products-todo.queries';
import { Observable, delay, distinctUntilKeyChanged } from 'rxjs';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-products-todo',
  templateUrl: './products-todo.component.html',
  styleUrl: './products-todo.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsTodoComponent implements OnInit {
  @Select(ProductsTodoQueries.issuesLoading)
  loading$: Observable<boolean>;

  @Select(ProductsTodoQueries.issuesData)
  issues$: Observable<Issue[]>;

  @Select(ProductsTodoQueries.issuesDataAvailable)
  issuesAvailable$: Observable<boolean>;

  readonly ISSUES_STATUS = IssueStatusColors;

  readonly COLUMNS_STRINGS = STRINGS.columns.products;

  readonly TITLE = STRINGS.metadata.todoList.productsTitle;

  readonly COMMON_STRINGS = MESSAGES.common;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        distinctUntilKeyChanged('uuid'),
        delay(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new LoadProductsIssues());
      });
  }

  goToProducts(): void {
    this.store.dispatch(new NavigateTo(['products']));
  }

  filterProductsIssues(issue: string): void {
    this.store.dispatch([
      new RemoveAllFilters(),
      new AddFilterParamNoReload(PRODUCT_FILTERS[issue]),
      new NavigateTo(['products']),
    ]);
  }
}
