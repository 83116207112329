import { ChangeDetectionStrategy, Component } from '@angular/core';
import { STRINGS } from '../../model/todo-list-v2.strings';

@Component({
  selector: 'app-todo-loading',
  templateUrl: './todo-loading.component.html',
  styleUrl: './todo-loading.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLoadingComponent {
  readonly STRINGS = STRINGS.metadata.todoList;
}
