import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { StockLevelHistoryState } from 'src/app/core/states/stock-level.state';
import { ServiceLevelInfoState } from '../dashboard/components/service-level-info/state/service-level-info.state';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { CustomDecimalPipe } from 'src/app/shared/pipes/custom-decimal.pipe';
import { AnalyticsComponent } from './analytics.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/core/modules/material/material.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ProgressSpinnerModule } from 'src/app/shared/components/design-system/progress-spinner/progress-spinner.module';
import { DataTableV2Module } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.module';

import * as echarts from 'echarts';
import { ServiceLevelChartPluginComponent } from '../dashboard/components/service-level-chart-plugin/service-level-chart-plugin.component';
import { ServiceLevelInfoComponent } from '../dashboard/components/service-level-info/service-level-info.component';
import { CdkTableModule } from '@angular/cdk/table';
import { DashboardModule } from '../dashboard/dashboard.module';
import { VizzlyDashboardState } from '../dashboard/components/vizzly-dashboard/state/vizzly-dashboard.state';
import { VizzlyDashboardComponent } from '../dashboard/components/vizzly-dashboard/vizzly-dashboard.component';

@NgModule({
  declarations: [
    AnalyticsComponent,
    ServiceLevelChartPluginComponent,
    ServiceLevelInfoComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    NgxEchartsModule.forRoot({ echarts }),
    ProgressSpinnerModule,
    DataTableV2Module,
    CdkTableModule,
    NgxsModule.forFeature([
      StockLevelHistoryState,
      ServiceLevelInfoState,
      VizzlyDashboardState,
    ]),
    DashboardModule,
    VizzlyDashboardComponent,
  ],
  providers: [DecimalPipe, CustomCurrencyPipe, CustomDecimalPipe],
})
export class AnalyticsModule {}
