import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DashboardDetails } from 'src/app/shared/models/stock-level/dashboard-cache.model';
import { LoadDashboardCache } from '../actions/dashboard-cache.action';
import { DashboardCacheService } from '../api/stock-level/v1/dashboard-cache.service';

export interface DashboardCacheStateModel {
  cache: DashboardDetails;
}

@State<DashboardCacheStateModel>({
  name: 'dashboardCacheState',
  defaults: { cache: null },
})
@Injectable()
export class DashboardCacheState {
  constructor(private cacheService: DashboardCacheService) {}

  @Selector()
  static cache(state: DashboardCacheStateModel): DashboardDetails {
    return state.cache;
  }

  @Action(LoadDashboardCache)
  loadDashboardCache(
    ctx: StateContext<DashboardCacheStateModel>,
    action: LoadDashboardCache
  ) {
    return this.cacheService.fetchCache(action.webshopId).pipe(
      catchError(() => {
        return of(null);
      }),
      tap((cache: DashboardDetails | null) => {
        ctx.patchState({ cache });
      })
    );
  }
}
