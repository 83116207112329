import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';

import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { StatusPageAdapter } from './adapters/status-page.adapter';
import { Incident as IncidentV1 } from './model/status-page.model';
import { Incidents } from 'src/app/features/home/components/toolbar/components/status-page/model/status-page.model';

@Injectable({
  providedIn: 'root',
})
export class StatusPageService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findStatusIncidents(
    replacements: UrlReplacement[]
  ): Observable<IncidentV1[]> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'incidents',
      'v1',
      'findStatusIncidents',
      replacements
    );
    return this.http.get<IncidentV1[]>(endpoint, { params: { limit: 3 } });
  }

  findStatusIncidents(): Observable<Incidents> {
    const replacements: UrlReplacement[] = [
      { name: 'page', value: 'bgqt3skkgg25' },
    ];

    return this._findStatusIncidents(replacements).pipe(
      switchMap((data: IncidentV1[]) =>
        of(new StatusPageAdapter().transform(data))
      )
    );
  }
}
