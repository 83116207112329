<app-todo-wrapper todoIcon="box-closed" [todoLabel]="TITLE">
  <button appButton variant="secondary" todoAction (click)="goToProducts()">
    {{ COMMON_STRINGS.goTo }} {{ TITLE | lowercase }}
  </button>

  @if (loading$ | async) {
    <app-todo-loading />
  } @else if ((issuesAvailable$ | async) === false) {
    <app-todo-no-issues />
  } @else {
    <app-todo-data-table>
      <table>
        <thead>
          <tr>
            <th>{{ COLUMNS_STRINGS.STATUS }}</th>
            <th>{{ COLUMNS_STRINGS.DESCRIPTION }}</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (issue of issues$ | async; track issue.status) {
            <tr>
              <td [appTodoIssueStatus]="issue.color">
                @if (issue.color === ISSUES_STATUS.CRITICAL) {
                  <app-todo-issue-icon
                    icon="warning-rounded-xs"
                    [status]="issue.color" />
                } @else if (issue.color === ISSUES_STATUS.WARNING) {
                  <app-todo-issue-icon
                    icon="warning-triangle"
                    [status]="issue.color" />
                }
              </td>
              <td>
                <strong>{{ issue.subject }}</strong> {{ issue.description }}
              </td>
              <td class="align-right fit-to-value">
                <button
                  appButton
                  variant="link"
                  size="sm"
                  (click)="filterProductsIssues(issue.status)">
                  {{ COMMON_STRINGS.view }}
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </app-todo-data-table>
  }
</app-todo-wrapper>
