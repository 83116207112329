import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  LoadStockLevelHistory,
  StockLevelLegendSelectedChanged,
} from 'src/app/core/actions/stock-level.action';
import { StockLevelHistoryState } from 'src/app/core/states/stock-level.state';
import { WebshopState } from 'src/app/core/states/webshop.state';

import { StockLevelHistoryOptions } from 'src/app/shared/models/stock-level/history.model';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { Chart } from 'src/app/shared/components/chart/chart.component';
import { ChartLoadingOptions } from 'src/app/shared/components/chart/model/chart.model';
import { LIGHT_THEME_PALLETE } from 'src/app/core/constants/themes-pallete.constants';
import { STRINGS } from '../../model/dashboard.strings';
import {
  ToggleMenuExpansion,
  ToggleMenuVisilibity,
} from 'src/app/features/home/actions/nav-menu-v2.actions';

@Component({
  selector: 'app-service-level-chart-plugin',
  templateUrl: './service-level-chart-plugin.component.html',
  styleUrls: ['./service-level-chart-plugin.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceLevelChartPluginComponent
  extends Chart<StockLevelHistoryOptions>
  implements OnInit, OnDestroy
{
  @Select(AccountSettingsState.colorThemeLowercase)
  colorTheme$: Observable<string>;

  @Select(StockLevelHistoryState.loading)
  loading$: Observable<boolean>;

  @Select(StockLevelHistoryState.loadingOptions)
  loadingOptions$: Observable<ChartLoadingOptions>;

  @Select(StockLevelHistoryState.graphOptions)
  graphOptions$: Observable<StockLevelHistoryOptions>;

  /**
   * Echart current instance.
   */
  echartsIntance: any;

  readonly STRINGS = STRINGS.metadata.serviceLevel;

  private destroy$ = new Subject<void>();

  constructor(
    private actions: Actions,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(ToggleMenuVisilibity), takeUntil(this.destroy$))
      .subscribe(() => {
        this.resizeChart();
      });

    this.actions
      .pipe(ofActionDispatched(ToggleMenuExpansion), takeUntil(this.destroy$))
      .subscribe(() => {
        this.resizeChart();
      });

    this.store
      .select(WebshopState.selected)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.echartsIntance) {
          this.store.dispatch(new LoadStockLevelHistory());
        }
      });
  }

  legendSelectedChanged(event: any) {
    this.store.dispatch(new StockLevelLegendSelectedChanged(event));
  }

  onWindowResize(_event: any): void {
    if (this.echartsIntance != null) {
      this.echartsIntance.resize();
    }
  }

  /**
   * Called when the echarts instance finishes loading and is
   * inserted in the dom.
   * @param ec the echarts instance that was loaded.
   */
  onChartInit(ec: any): void {
    this.echartsIntance = ec;

    this.store.dispatch(new LoadStockLevelHistory());

    this.echartsIntance.showLoading({
      text: '',
      color: LIGHT_THEME_PALLETE.blue_3,
      textColor: '#000',
      maskColor: 'rgba(255, 255, 255, 0)',
      zlevel: 0,

      fontSize: 12,
      showSpinner: true,
      spinnerRadius: 12,
      lineWidth: 2,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontFamily: 'sans-serif',
    });
  }

  /**
   * Resizes the chart dom component.
   */
  resizeChart(): void {
    if (this.echartsIntance) {
      setTimeout(() => {
        this.echartsIntance.resize();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
