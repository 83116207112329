export interface UpdateParameters {
  index: number;
  desiredLevel: number;
}

export interface UpdateDashboardCache {
  index: number;
  currentServiceLevel: number;
  inventoryValue: number;
  inventoryTrendValue: number;
  stockTurnOver: number;
  revenue: number;
}

export class ServiceLevelInfoUpdateParameters {
  static readonly type = '[Service Level Info] Update Parameters';

  constructor(public payload: UpdateParameters) {}
}

export class ServiceLevelInfoUpdateCache {
  static readonly type = '[Service Level Info] Update Cache';

  constructor(public payload: UpdateDashboardCache) {}
}
