import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import {
  LoadWebshopMetrics,
  ResetWebshopMetrics,
} from '../actions/webshop-metrics.actions';
import { WebshopMetricsService } from 'src/app/core/api/metrics/v1/webshop-metrics.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { catchError, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';
import { webshopMetricsDefaults } from '../model/webshop-metrics.model';

export interface WebshopMetricsStateModel extends WebshopMetrics {
  loading: boolean;
}

@State<WebshopMetricsStateModel>({
  name: 'webshopMetricsState',
  defaults: webshopMetricsDefaults,
})
@Injectable()
export class WebshopMetricsState {
  private webshopMetricsService = inject(WebshopMetricsService);

  private store = inject(Store);

  @Action(LoadWebshopMetrics)
  loadWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return this._fetchWebshopMetrics(ctx).pipe(
      tap(metrics => {
        ctx.setState(
          patch({
            loading: false,
            date: metrics.date,
            serviceLevel: metrics.serviceLevel,
            previousServiceLevel: metrics.previousServiceLevel,
            serviceLevelDiff: metrics.serviceLevelDiff,
            inventoryValue: metrics.inventoryValue,
            turnoverTime: metrics.turnoverTime,
            deadstockValue: metrics.deadstockValue,
            inFullLevel: metrics.inFullLevel,
            previousInFullLevel: metrics.previousInFullLevel,
            inFullLevelDiff: metrics.inFullLevelDiff,
            onTimeLevel: metrics.onTimeLevel,
            previousOnTimeLevel: metrics.previousOnTimeLevel,
            onTimeLevelDiff: metrics.onTimeLevelDiff,
            trend: metrics.trend,
            seasonality: metrics.seasonality,
          })
        );
      })
    );
  }

  @Action(ResetWebshopMetrics)
  resetWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    ctx.patchState(webshopMetricsDefaults);
  }

  private _fetchWebshopMetrics(ctx: StateContext<WebshopMetricsStateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.webshopMetricsService.fetchWebshopMetrics(webshopUuid).pipe(
      catchError(e => {
        ctx.patchState({
          ...webshopMetricsDefaults,
          loading: false,
        });

        throw new Error(e.message || e);
      })
    );
  }
}
