import { Injectable, inject } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  Observable,
  distinctUntilKeyChanged,
  filter,
  mergeMap,
  switchMap,
} from 'rxjs';
import {
  FindWebshopOwner,
  LoadAccountPermissions,
} from '../actions/account-permissions.actions';
import { WebshopState } from '../states/webshop.state';
import { AccountState } from '../states/account.state';

@Injectable({
  providedIn: 'root',
})
export class AccountRolesResolver {
  private store = inject(Store);

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    return this.store.select(WebshopState.selected).pipe(
      filter(webshop => !!webshop),
      distinctUntilKeyChanged('uuid'),
      switchMap(() => this.store.select(AccountState.userUuid)),
      filter(Boolean),
      mergeMap(() =>
        this.store.dispatch([
          new FindWebshopOwner(),
          new LoadAccountPermissions(),
        ])
      )
    );
  }
}
