<div class="container">
  @if (metrics(); as metrics) {
    <app-info-card
      data-intercom-target="Metrics service level card"
      aria-label="Metrics service level card"
      [cardTitle]="METRICS_STRINGS.metadata.serviceLevel">
      <mat-icon
        cardTitleTooltip
        svgIcon="questionmark-rounded"
        (click)="openCurrentServiceLevelHelpArticle()"
        [appTooltip]="METRICS_STRINGS.tooltips.currentServiceLevel" />

      @if (loading()) {
        <app-skeleton-rect />
      } @else if (metrics.serviceLevel !== null) {
        <div class="content">
          <span>
            {{ metrics.serviceLevel | customDecimal: '1.1-2' }}
            <span class="gray"><small> %</small></span>
          </span>

          @if (metrics.previousServiceLevel !== null) {
            <app-webshop-metrics-difference
              [previousValue]="metrics.previousServiceLevel"
              [difference]="metrics.serviceLevelDiff" />
          }
        </div>
      } @else {
        {{ NOT_AVAILABLE }}
      }
    </app-info-card>

    <app-info-card
      data-intercom-target="Metrics turnover time card"
      aria-label="Metrics turnover time card"
      [cardTitle]="METRICS_STRINGS.metadata.turnoverTime">
      <mat-icon
        cardTitleTooltip
        svgIcon="questionmark-rounded"
        (click)="openInventoryTurnoverTimeHelpArticle()"
        [appTooltip]="METRICS_STRINGS.tooltips.inventoryTurnoverTime" />
      @if (loading()) {
        <app-skeleton-rect />
      } @else if (metrics.turnoverTime !== null) {
        @if (metrics.turnoverTime > 365) {
          <div>
            365+<span class="unit gray"
              ><small> {{ COMMON_STRINGS.daySymbol }}</small></span
            >
          </div>
        } @else {
          <div>
            {{ metrics.turnoverTime
            }}<span class="unit gray"
              ><small> {{ COMMON_STRINGS.daySymbol }}</small></span
            >
          </div>
        }
      } @else {
        {{ NOT_AVAILABLE }}
      }
    </app-info-card>

    <app-info-card
      data-intercom-target="Metrics service level card"
      aria-label="Metrics service level card"
      [cardTitle]="METRICS_STRINGS.metadata.inFullLevel">
      <mat-icon
        cardTitleTooltip
        svgIcon="questionmark-rounded"
        (click)="openPurchaseAutomationHelpArticle()"
        [appTooltip]="METRICS_STRINGS.tooltips.purchaseAutomation" />
      @if (loading()) {
        <app-skeleton-rect />
      } @else if (metrics.inFullLevel !== null) {
        <div class="content">
          <span
            >{{ metrics.inFullLevel | customDecimal: '1.1-2'
            }}<span class="gray"><small> %</small></span></span
          >

          @if (metrics.previousInFullLevel !== null) {
            <app-webshop-metrics-difference
              [previousValue]="metrics.previousInFullLevel"
              [difference]="metrics.inFullLevelDiff" />
          }
        </div>
      } @else {
        {{ NOT_AVAILABLE }}
      }
    </app-info-card>

    <app-info-card
      data-intercom-target="Metrics service level card"
      aria-label="Metrics service level card"
      [cardTitle]="METRICS_STRINGS.metadata.onTimeLevel">
      <mat-icon
        cardTitleTooltip
        svgIcon="questionmark-rounded"
        (click)="openOnTimePurchasingHelpArticle()"
        [appTooltip]="METRICS_STRINGS.tooltips.onTimePurchasing" />
      @if (loading()) {
        <app-skeleton-rect />
      } @else if (metrics.onTimeLevel !== null) {
        <div class="content">
          <span
            >{{ metrics.onTimeLevel | customDecimal: '1.1-2'
            }}<span class="gray"><small> %</small></span></span
          >

          @if (metrics.previousOnTimeLevel !== null) {
            <app-webshop-metrics-difference
              [previousValue]="metrics.previousOnTimeLevel"
              [difference]="metrics.onTimeLevelDiff" />
          }
        </div>
      } @else {
        {{ NOT_AVAILABLE }}
      }
    </app-info-card>
  }
</div>
