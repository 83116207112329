import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ConfigStoreService } from '../../services/config-store.service';
import { VizzlyDashboardAdapter } from './adapters/vizzly-dashboard.adapter';

export interface AccessTokens {
  dataAccessToken: string;
  dashboardAccessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class VizzlyDashboardService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _queryEngine(): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'vizzlyDashboard',
      'v1',
      'queryEngine',
      null
    );

    return this.http.get(endpoint);
  }

  private _getAccessTokens(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'vizzlyDashboard',
      'v1',
      'identityFetch',
      replacements
    );

    return this.http.post<AccessTokens>(endpoint, null);
  }

  queryEngine(): Observable<any> {
    return this._queryEngine();
  }

  getAccessTokens(webshopUuid: string): Observable<AccessTokens> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];
    return this._getAccessTokens(replacements).pipe(
      switchMap((data: AccessTokens) =>
        of(new VizzlyDashboardAdapter().transform(data))
      )
    );
  }
}
