import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { VizzlyDashboardState } from './state/vizzly-dashboard.state';
import { VizzlyDashboardService } from 'src/app/core/api/vizzly-dashboard/vizzly-dashboard.service';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { environment } from 'src/environments/environment';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { GetAccessTokens } from './actions/vizzly-dashboard.actions';

interface Dashboard {
  render: (config: any) => void;
}

declare var dashboard: Dashboard;

@Component({
  selector: 'dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vizzly-dashboard.component.html',
  styleUrls: ['./vizzly-dashboard.component.sass'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VizzlyDashboardComponent implements AfterViewInit, OnInit {
  @Select(VizzlyDashboardState.dataAccessToken)
  dataAccessToken: string;

  @Select(VizzlyDashboardState.dashboardAccessToken)
  dashboardAccessToken: string;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  constructor(private vizzlyDashboardService: VizzlyDashboardService) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((webshop: Webshop) => {
        this.store.dispatch(new GetAccessTokens());
      });
  }

  ngAfterViewInit() {
    dashboard.render({
      developerTools: { theme: false },
      theme: {
        baseFont: {
          fontSize: 50,
        },
        rowLimit: 5,
        detail: 'minimal',
        panels: {
          background: 'var(--shade-1)',
          color: 'var(--ink-1)',
          fontSize: 14,
          padding: '4px 16px 4px 16px',
          fontFamily: 'sans-serif',
          p: {
            fontSize: '12px',
          },
        },
        tables: {
          th: {
            background: 'var(--shade-3)',
            color: 'var(--ink-2)',
            fontWeight: 500,
            fontSize: 14,
            borderBottom: '1px solid var(--shade-7)',
            borderLeft: '1px solid var(--shade-7)',
            borderRight: '1px solid var(--shade-7)',
            borderTop: '1px solid var(--shade-7)',
            borderRadius: 8,
            textTransform: 'inherit',
            padding: 12,
            '&:hover': {
              color: 'var(--ink-2)',
              background: 'var(--shade-4)',
            },
            inner: {
              borderBottomWidth: '1px solid var(--shade-7)',
              borderRight: '1px solid var(--shade-7)',
            },
            first: {
              borderLeft: '1px solid var(--shade-7)',
            },
            last: {
              borderRight: '1px solid var(--shade-7)',
            },
          },
          body: {
            odd: {
              background: 'inherit',
              color: 'var(--ink-1)',
              '&:hover': {
                background: 'var(--shade-3)',
              },
            },
            even: {
              background: 'inherit',
              color: 'var(--ink-1)',
              '&:hover': {
                background: 'var(--shade-3)',
              },
            },
            borderLeft: '1px solid var(--shade-7)',
            borderRight: '1px solid var(--shade-7)',
            cells: {
              fontSize: 14,
              padding: 16,
              borderBottom: '1px solid var(--shade-7)',
            },
          },
        },
        buttons: {
          secondary: {
            fontWeight: 500,
            color: 'var(--ink-2)',
            background: 'var(--shade-4)',
            boxShadow: 'unset',
            borderColor: 'unset',
            fontSize: 14,
            padding: '8px 16px 8px 16px',
            '&:hover': {
              background: 'var(--shade-5)',
              color: 'var(--ink-2)',
              opacity: 1,
            },
          },
          primary: {
            fontWeight: 500,
            color: 'var(--shade-1)',
            background: 'var(--blue-3)',
            boxShadow: 'unset',
            border: '1px solid transparent',
            fontSize: 14,
            padding: '8px 16px 8px 16px',
            '&:hover': {
              border: '1px solid transparent',
              background: 'var(--blue-4)',
              color: 'var(--shade-1)',
            },
          },
          add: {
            background: 'transparent',
            border: '1px solid transparent',
            color: 'var(--ink-3)',
            borderRadius: 6,
            '&:hover': {
              color: 'var(--ink-2)',
              background: 'var(--shade-8)',
              border: '0px solid var(--shade-8)',
            },
            small: {
              fontSize: 14,
              fontWeight: 500,
            },
          },
          grid: {
            selected: {
              borderRadius: 6,
              fontWeight: 400,
              background: 'var(--shade-1)',
              fontSize: 14,
              color: 'var(--ink-2)',
              boxShadow: 'unset',
              border: 'unset',
              '&:hover': {
                boxShadow: 'unset',
                border: 'unset',
                background: 'var(--shade-2)',
              },
            },
            unselected: {
              borderRadius: 6,
              fontWeight: 400,
              background: 'transparent',
              fontSize: 14,
              color: 'var(--ink-2)',
              border: '1px solid transparent',
              '&:hover': {
                boxShadow: 'unset',
                background: 'rgb(48, 54, 61)',
                border: '1px solid transparent',
              },
            },
          },
        },
        alerts: {
          info: {
            background: 'var(--blue-1)',
            color: 'var(--blue-3)',
            border: '2px solid var(--blue-3)',
            borderRadius: 6,
            iconColor: 'var(--blue-3)',
          },
          warning: {
            background: 'var(--red-1)',
            color: 'var(--red-3)',
            border: '2px solid var(--red-3)',
            borderRadius: 8,
            iconColor: 'var(--red-3)',
          },
        },
        forms: {
          label: {
            color: 'var(--ink-2)',
            fontSize: 14,
            fontWeight: 600,
          },
          input: {
            color: 'var(--ink-2)',
            background: 'var(--shade-2)',
            padding: '8px 16px',
            border: '2px solid var(--shade-7)',
            borderRadius: 8,
            fontSize: 14,
            '&:focus': {
              border: '2px solid var(--shade-7)',
              boxShadow:
                '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
            },
            '&:focus:hover': {
              boxShadow:
                '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
            },
            '&:hover': {
              background: 'var(--shade-1)',
              border: '2px solid var(--shade-8)',
            },
          },
          select: {
            background: 'var(--shade-2)',
            fontSize: 14,
            padding: '8px 16px',
            border: '2px solid var(--shade-7)',
            borderRadius: 8,
            boxShadow: 'unset',
            color: 'var(--ink-2)',
            '&:hover': {
              background: 'var(--shade-1)',
              border: '2px solid var(--shade-8)',
            },
            '&:focus': {
              border: '2px solid var(--shade-7)',
              boxShadow:
                '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
            },
            indicatorColor: 'var(--ink-3)',
          },
          dateTime: {
            input: {
              background: 'var(--shade-2)',
              borderRadius: 8,
              border: '2px solid var(--shade-7)',
              fontSize: 14,
              boxShadow: 'inset 0 0 0 1px  transparent',
              color: 'var(--ink-2)',
              padding: '0.25rem 0.5rem',
              '&:focus': {
                border: '2px solid var(--shade-7)',
                boxShadow:
                  '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
              },
              '&:focus:hover': {
                boxShadow:
                  '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
              },
              '&:hover': {
                background: 'var(--shade-1)',
                border: '2px solid var(--shade-8)',
              },
            },
            date: {
              width: 96,
            },
            time: {
              width: 64,
            },
            indicatorColor: 'var(--ink-3)',
          },
        },
        dropdowns: {
          fontSize: 14,
          background: 'var(--shade-1)',
          color: 'var(--ink-2)',
          boxShadow: 'var(--shadow-7)',
          borderRadius: 6,
          border: 'var(--shade-7)',
          separatorBorder: '1px solid transparent',
          '&:hover': {
            background: 'var(--shade-3)',
          },
          padding: '8px 16px 8px 16px',
        },
        lists: {
          border: '2px var(--shade-2)',
          color: 'var(--ink-2)',
          boxShadow: 'var(--shadow-1)',
          borderRadius: 6,
          background: 'var(--shade-1)',
          icon: {
            background: 'var(--shade-7)',
            border: 'var(--shadow-7)',
          },
        },
        tabs: {
          selected: {
            borderRadius: 8,
            padding: '12px 2px',
            fontSize: 14,
            fontWeight: 600,
            background: 'var(--shade-4)',
            color: 'var(--blue-3)',
            margin: 10,
          },
          unselected: {
            borderRadius: 0,
            padding: '12px 2px',
            fontSize: 14,
            fontWeight: 500,
            background: 'var(--shade-1)',
            color: 'var(--ink-2)',
            '&:hover': {
              color: 'var(--blue-3)',
              background: 'var(--shade-2)',
              borderRadius: 8,
            },
          },
        },
        modals: {
          base: {
            background: 'var(--shade-1)',
            borderColor: 'solid transparent',
            borderRadius: 8,
            padding: '8px 4px 8px 4px',
            color: 'var(--ink-2)',
          },
          content: {
            background: 'var(--shade-1)',
          },
          header: {
            background: 'none',
          },
          options: {
            closeButton: 'none',
          },
        },
        charts: {
          padding: '24px 16px 24px 16px',
          colors: [
            '#4AB573',
            '#659EF2',
            '#E56262',
            '#659ef2',
            '#ff9c3f',
            '#9672c1',
          ],
          title: {
            fontSize: 16,
            color: 'var(--ink-1)',
            fontWeight: 600,
            fontFamily: 'sans-serif',
          },
          labels: {
            color: 'var(--ink-2)',
            fontWeight: 300,
            fontSize: 12,
          },
          subject: {
            fontSize: 12,
            color: 'var(--ink-2)',
          },
          grid: {
            stroke: 'var(--shade-8)',
          },
          axis: {
            stroke: 'var(--shade-8)',
          },
          iconColor: 'var(--ink-3)',
        },

        popoverMenu: {
          background: 'var(--shade-1)',
          border: '0px solid transparent',
          color: 'var(--ink-2)',
          borderRadius: 12,
          padding: '8px 16px 8px 16px',
          boxShadow: 'var(--shadow-7)',
          '&:hover': {
            background: 'var(--shade-3)',
            color: 'var(--ink--2)',
          },
        },
        accordion: {
          iconColor: 'var(--ink-3)',
          button: {
            fontWeight: 300,
            fontSize: 14,
            color: 'var(--ink-2)',
          },
        },
        dashboard: {
          border: 'var(--shade-1)',
          borderWidth: 4,
          borderRadius: 8,
        },
        search: {
          background: 'var(--shade-2)',
          border: '1px solid var(--shade-7)',
          color: 'var(--ink-1)',
          padding: '4px 16px 4px 16px',
          fontSize: 16,
          height: 32,
          '&:focus': {
            boxShadow:
              '0px 0px 0px 4px rgba(55, 147, 255, 0.24), 0px 0px 0px 2px #3793FF',
          },
          '&:hover': {
            background: 'var(--shade-2)',
            boxShadow: '0 0 0 3px transparent',
          },
        },
        library: {
          verified: {
            color: '#009EE3',
          },
        },
      },

      queryEngineEndpoint: {
        endpoint: environment.production
          ? 'https://app.optiply.com/api/vizzly/v1/'
          : 'https://dashboard.acceptance.optiply.com/api/vizzly/edge/',
        customHeaders: () => ({
          Authorization:
            'Bearer ' + this.store.selectSnapshot(AuthenticationState.token),
        }),
      },
      identity: async () => {
        return {
          dataAccessToken: this.store.selectSnapshot(
            VizzlyDashboardState.dataAccessToken
          ),
          dashboardAccessToken: this.store.selectSnapshot(
            VizzlyDashboardState.dashboardAccessToken
          ),
        };
      },
    });
  }
}
