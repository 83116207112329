import { ChangeDetectionStrategy, Component } from '@angular/core';
import { STRINGS } from '../../model/todo-list-v2.strings';

@Component({
  selector: 'app-todo-no-issues',
  templateUrl: './todo-no-issues.component.html',
  styleUrl: './todo-no-issues.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoNoIssuesComponent {
  readonly STRINGS = STRINGS.metadata.todoList;
}
