import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import {
  StockLevelHistory,
  StockLevelHistoryOptions,
} from 'src/app/shared/models/stock-level/history.model';
import { LoadFailed } from '../actions/app.action';
import {
  LoadStockLevelHistory,
  ResetStockLevelHistory,
  StockLevelLegendSelectedChanged,
} from '../actions/stock-level.action';
import { StockLevelService } from '../api/stock-level/v1/stock-history.service';
import { MESSAGES } from '../constants/strings.constants';
import { LIGHT_THEME_PALLETE } from '../constants/themes-pallete.constants';
import { WebshopState } from './webshop.state';

import * as echarts from 'echarts/lib/echarts';
import { ChartLoadingOptions } from 'src/app/shared/components/chart/model/chart.model';
import { AccountSettingsState } from './account-settings.state';
import { ServiceLevelGraphCategories } from 'src/app/shared/models/account/account-settings.model';

export interface StockLevelHistoryStateModel {
  loading: boolean;
  history: StockLevelHistory;
  graphOptions: StockLevelHistoryOptions;
  loadingOptions: ChartLoadingOptions;
}

const defaults: StockLevelHistoryStateModel = {
  loading: true,
  history: null,
  graphOptions: {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross', label: {} },
    },
    legend: {
      left: 'center',
      top: 'bottom',
      data: [
        MESSAGES.common.stockLevelHistory.categoryA,
        MESSAGES.common.stockLevelHistory.categoryB,
        MESSAGES.common.stockLevelHistory.categoryC,
      ],
      selected: {
        [MESSAGES.common.stockLevelHistory.categoryA]: true,
        [MESSAGES.common.stockLevelHistory.categoryB]: true,
        [MESSAGES.common.stockLevelHistory.categoryC]: true,
      },
    },
    grid: {
      left: '8px',
      right: '20px',
      bottom: '32px',
      top: '12px',
      containLabel: true,
    },
    xAxis: [{ type: 'category', boundaryGap: false, data: [] }],
    yAxis: [{ type: 'value' }],
    series: [
      {
        name: MESSAGES.common.stockLevelHistory.categoryA,
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        smooth: true,
        data: [],
        showSymbol: false,
        color: '#4AB573',
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(74, 181, 115, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(74, 181, 115, 0)',
            },
          ]),
        },
      },
      {
        name: MESSAGES.common.stockLevelHistory.categoryB,
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        smooth: true,
        data: [],
        showSymbol: false,
        color: '#659EF2',
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(101, 157, 242, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(101, 157, 242, 0)',
            },
          ]),
        },
      },
      {
        name: MESSAGES.common.stockLevelHistory.categoryC,
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        smooth: true,
        data: [],
        showSymbol: false,
        color: '#E56262',
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(229, 98, 98, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(229, 98, 98, 0)',
            },
          ]),
        },
      },
    ],
  },
  loadingOptions: {
    text: '',
    color: LIGHT_THEME_PALLETE.blue_3, // default
    textColor: '#000',
    maskColor: 'rgba(255, 255, 255, 0)',
    zlevel: 0,

    fontSize: 12,
    showSpinner: true,
    spinnerRadius: 12,
    lineWidth: 2,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
  },
};

@State<StockLevelHistoryStateModel>({
  name: 'stockLevelHistoryState',
  defaults,
})
@Injectable()
export class StockLevelHistoryState {
  constructor(
    private store: Store,
    private stockLevelService: StockLevelService
  ) {}

  @Selector()
  static history(state: StockLevelHistoryStateModel): StockLevelHistory {
    return state.history;
  }

  @Selector()
  static loading(state: StockLevelHistoryStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static loadingOptions(
    state: StockLevelHistoryStateModel
  ): ChartLoadingOptions {
    return state.loadingOptions;
  }

  @Selector()
  static graphOptions(
    state: StockLevelHistoryStateModel
  ): StockLevelHistoryOptions {
    return state.graphOptions;
  }

  @Action(LoadStockLevelHistory)
  loadDashboardCache(ctx: StateContext<StockLevelHistoryStateModel>) {
    const webshopId = this.store.selectSnapshot(WebshopState.selected).id;

    return ctx.dispatch(new ResetStockLevelHistory()).pipe(
      concatMap(() => this._fetchStockHistory(ctx, { webshopId })),
      tap((history: StockLevelHistory) => {
        this._updateStockLevelHistoryOptions(ctx, history);
      }),
      tap(() => {
        ctx.setState(
          patch<StockLevelHistoryStateModel>({
            loading: false,
          })
        );
      }),
      catchError(() => {
        this._setNoDataErrorMessage(ctx);

        return of(null);
      })
    );
  }

  @Action(ResetStockLevelHistory, { cancelUncompleted: true })
  reset(ctx: StateContext<StockLevelHistoryStateModel>) {
    ctx.patchState(defaults);
  }

  @Action(StockLevelLegendSelectedChanged, { cancelUncompleted: true })
  legendSelectedChanged(
    ctx: StateContext<StockLevelHistoryStateModel>,
    action: StockLevelLegendSelectedChanged
  ) {
    ctx.setState(
      patch<StockLevelHistoryStateModel>({
        graphOptions: patch<StockLevelHistoryOptions>({
          ...ctx.getState().graphOptions,
          legend: {
            ...ctx.getState().graphOptions.legend,
            selected: {
              ...action.event.selected,
            },
          },
        }),
      })
    );
  }

  private _fetchStockHistory(
    ctx: StateContext<StockLevelHistoryStateModel>,
    payload: { webshopId: number }
  ): Observable<StockLevelHistory> {
    return this.stockLevelService.fetchStockHistory(payload.webshopId).pipe(
      catchError(e => {
        ctx.dispatch(
          new LoadFailed(
            true,
            MESSAGES.notifications.stockLevelHistoryGraph.loadFailed
          )
        );

        throw new Error(e.message || e);
      })
    );
  }

  /**
   * Sets no data error message
   * This is used when theres no data at all or an error occurs.
   * @param ctx StateContext(StockLevelHistoryStateModel)
   */
  private _setNoDataErrorMessage(
    ctx: StateContext<StockLevelHistoryStateModel>
  ): void {
    ctx.setState(
      patch<StockLevelHistoryStateModel>({
        loading: false,
        graphOptions: patch<StockLevelHistoryOptions>({
          title: {
            show: true,
            text: MESSAGES.notifications.stockLevelHistoryGraph.loadFailed,
            left: 'center',
            top: 'center',
          },
        }),
      })
    );
  }

  /**
   * Updates stock level history options
   * @param ctx StateContext(StockLevelHistoryStateModel)
   * @param history StockLevelHistory | null
   * @returns stock level history options
   */
  private _updateStockLevelHistoryOptions(
    ctx: StateContext<StockLevelHistoryStateModel>,
    history: StockLevelHistory | null
  ): void {
    if (!history) {
      this._setNoDataErrorMessage(ctx);
      return;
    }

    this._buildGraphData(ctx, history);
  }

  private _buildGraphData(
    ctx: StateContext<StockLevelHistoryStateModel>,
    history: StockLevelHistory
  ) {
    const state = ctx.getState();

    const [categoryA, categoryB, categoryC] = history.data;

    const webshop = this.store.selectSnapshot(WebshopState.selected);

    const excludedCategories = this.store.selectSnapshot(
      AccountSettingsState.serviceLevelGraphExcludedCategories
    );

    ctx.setState(
      patch<StockLevelHistoryStateModel>({
        graphOptions: patch<StockLevelHistoryOptions>({
          title: {
            ...state.graphOptions.title,
            show:
              history === null ||
              !history.labels ||
              history.labels.length === 0,
          },
          legend: {
            ...state.graphOptions.legend,
            selected: {
              [MESSAGES.common.stockLevelHistory.categoryA]:
                !excludedCategories.includes(ServiceLevelGraphCategories.A),
              [MESSAGES.common.stockLevelHistory.categoryB]:
                !excludedCategories.includes(ServiceLevelGraphCategories.B),
              [MESSAGES.common.stockLevelHistory.categoryC]:
                !excludedCategories.includes(ServiceLevelGraphCategories.C),
            },
          },
          tooltip: {
            trigger: 'axis',
            valueFormatter: value => value + ' %',
          },
          xAxis: [
            {
              ...state.graphOptions.xAxis[0],
              data: history.labels,
            },
          ],
          series: [
            {
              ...state.graphOptions.series[0],
              data: categoryA.values || [],
              markLine: {
                symbol: ['none', 'none'],
                data: [{ name: 'A', yAxis: webshop.serviceLevel.a }],
              },
            },
            {
              ...state.graphOptions.series[1],
              data: categoryB.values || [],
              markLine: {
                symbol: ['none', 'none'],
                data: [{ name: 'B', yAxis: webshop.serviceLevel.b }],
              },
            },
            {
              ...state.graphOptions.series[2],
              data: categoryC.values || [],
              markLine: {
                symbol: ['none', 'none'],
                data: [{ name: 'C', yAxis: webshop.serviceLevel.c }],
              },
            },
          ],
        }),
      })
    );
  }
}
