import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Incident as IncidentV1 } from '../model/status-page.model';
import {
  INCIDENT_BY_IMPACT_COLORS,
  INCIDENT_COLORS,
  Incident,
  Incidents,
} from 'src/app/features/home/components/toolbar/components/status-page/model/status-page.model';

export class StatusPageAdapter implements DataAdapter<IncidentV1[], Incidents> {
  transform(data: IncidentV1[]): Incidents {
    let iconStatus = data[0].status;
    let iconImpact = data[0].impact;

    const incidents: Incident[] = data
      .filter(
        incident =>
          incident.status !== 'resolved' &&
          incident.status !== 'completed' &&
          incident.status != 'postmortem'
      )
      .map((incident: IncidentV1) => {
        return {
          name: incident.name,
          status: this.sentenseCase(incident.status),
          impact: incident.impact,
          body: incident.incident_updates[0].body,
          updated_at: incident.updated_at,
          color:
            incident.status === 'scheduled' || iconStatus === 'maintenance'
              ? INCIDENT_COLORS[INCIDENT_COLORS.SCHEDULED]
              : INCIDENT_BY_IMPACT_COLORS[this.toUpperCase(incident.impact)],
        };
      });

    return {
      incidents: incidents,
      iconColor:
        incidents.length === 0
          ? 'green'
          : iconStatus === 'scheduled' || iconStatus === 'maintenance'
            ? INCIDENT_COLORS[INCIDENT_COLORS.SCHEDULED]
            : INCIDENT_BY_IMPACT_COLORS[this.toUpperCase(iconImpact)],
    };
  }

  /**
   * Converts a string to sentence case
   * @param type
   * @returns sentence case
   */
  private toUpperCase(type: string): string {
    if (type) {
      return type.toUpperCase().replace('_', ' ');
    }
  }
  private sentenseCase(type: string): string {
    if (type) {
      return type.replace('_', ' ');
    }
  }
}
