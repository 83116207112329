import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in-authenticated',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInAuthenticatedComponent implements AfterViewInit {
  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.router.navigate(['webshops']);
  }
}
