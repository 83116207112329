<div
  class="container"
  [ngClass]="differenceClass()"
  appTooltip="{{ TOOLTIPS.previousMonth }} {{ previousValue() }}%">
  <span>
    {{ differencePositiveFormat() }}
    <span class="difference-percentage">%</span>
  </span>

  <mat-icon [svgIcon]="differenceIcon()" />
</div>
