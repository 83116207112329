import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { StatusPageState } from 'src/app/features/home/state/status-page.state';
import { Incident } from '../../model/status-page.model';
import { RefreshStatusIncidents } from 'src/app/features/home/actions/status-page.actions';

@Component({
  selector: 'app-status-page-dropdown',
  templateUrl: './status-page-dropdown.component.html',
  styleUrl: './status-page-dropdown.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPageDropdownComponent implements OnInit {
  MESSAGES = MESSAGES.common.statusPage;

  @Select(StatusPageState.incidents)
  incidents$: Observable<Incident[]>;

  @Select(StatusPageState.iconColor)
  iconColor$: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new RefreshStatusIncidents());
  }

  redirectToStatus(): void {
    window.open('https://status.optiply.com/', '_blank');
  }
}
