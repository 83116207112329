import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { STRINGS } from './model/dashboard.strings';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  readonly STRINGS = STRINGS.metadata;

  ngOnInit(): void {
    window.rudderanalytics.page('Dashboard');
  }
}
