import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { navAnimation } from './model/nav-menu-v2.animations';
import { Observable } from 'rxjs';
import { MenuOption } from './model/nav-menu-v2.model';
import { Select, Store } from '@ngxs/store';
import { NavMenuV2State } from '../../state/nav-menu-v2.state';
import { NavMenuV2StateQueries } from '../../state/nav-menu-v2.queries';
import { ToggleMenuExpansion } from '../../actions/nav-menu-v2.actions';

@Component({
  selector: 'app-nav-menu-v2',
  templateUrl: './nav-menu-v2.component.html',
  styleUrl: './nav-menu-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [navAnimation],
})
export class NavMenuV2Component {
  @Select(NavMenuV2State.closed)
  closed$: Observable<boolean>;

  @Select(NavMenuV2State.expanded)
  expanded$: Observable<boolean>;

  @Select(NavMenuV2StateQueries.primaryOptions)
  primaryOptions$: Observable<MenuOption[]>;

  @Select(NavMenuV2StateQueries.secondaryOptions)
  secondaryOptions$: Observable<MenuOption[]>;

  private store = inject(Store);

  onMenuStateChangeClicked() {
    this.store.dispatch(new ToggleMenuExpansion());
  }
}
