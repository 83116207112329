export interface Incident {
  name: string;
  status: string;
  updated_at: string;
  impact: string;
  body: string;
  color: string;
}

export interface Incidents {
  incidents: Incident[];
  iconColor: string;
}

export const INCIDENT_COLORS = {
  SCHEDULED: 'blue',
  RESOLVED: 'green',
  COMPLETED: 'green',
  IN_PROGRESS: 'orange',
  IDENTIFIED: 'orange',
  INVESTIGATING: 'red',
  VERIFYING: 'red',
};

export const INCIDENT_BY_IMPACT_COLORS = {
  NO_IMPACT: 'grey',
  MINOR: 'yellow',
  MAJOR: 'orange',
  CRITICAL: 'red',
};
