<div
  data-intercom-target="b8a68281-eb72-42b2-b6ab-1ec9fd5eb555"
  aria-label="Dashboard service level graph"
  class="container">
  <div class="header">
    <h1 class="title">{{ STRINGS.title }} <span class="unit">(%)</span></h1>
  </div>

  <div
    class="chart"
    #chart
    (window:resize)="onWindowResize($event)"
    echarts
    [theme]="'optiply-' + (colorTheme$ | async)"
    [autoResize]="false"
    [loading]="loading$ | async"
    [loadingOpts]="loadingOptions$ | async"
    [options]="graphOptions$ | async"
    (chartInit)="onChartInit($event)"
    (chartLegendSelectChanged)="legendSelectedChanged($event)"></div>
</div>
