import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const navAnimation = trigger('navAnimation', [
  state(
    'collapsed',
    style({
      width: '64px',
    })
  ),
  state(
    'expanded',
    style({
      width: '232px',
    })
  ),
  transition('collapsed => expanded', [animate('.2s ease')]),
  transition('expanded => collapsed', [animate('.2s ease')]),
]);
