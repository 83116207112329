import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  NavigateTo,
  NavigateToWithQueryParams,
  NavigationChanged,
  RefreshNavigation,
} from '../actions/navigation.action';
import { WebshopState } from './webshop.state';
import { BASE_PATH } from '../constants/global.constants';

export interface NavigationStateModel {
  path: string;
}

@State<NavigationStateModel>({
  name: 'navigationState',
  defaults: {
    path: null,
  },
})
@Injectable()
export class NavigationState {
  constructor(
    private store: Store,
    private router: Router,
    private zone: NgZone
  ) {}

  @Selector()
  static path(state: NavigationStateModel): string {
    return state.path;
  }

  @Action(NavigationChanged)
  saveRecentWebshop(
    ctx: StateContext<NavigationStateModel>,
    event: NavigationChanged
  ) {
    const state = ctx.getState();
    ctx.patchState({ ...state, path: event.data.path });
  }

  @Action(NavigateTo)
  navigateTo(_ctx: StateContext<NavigationStateModel>, payload: NavigateTo) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    this.zone.run(() => {
      this.router.navigate([BASE_PATH, webshopUuid, ...payload.path]);
    });
  }

  @Action(NavigateToWithQueryParams)
  navigateToWithQueryParams(
    _ctx: StateContext<NavigationStateModel>,
    payload: NavigateToWithQueryParams
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    this.zone.run(() => {
      this.router.navigate([BASE_PATH, webshopUuid, ...payload.path], {
        queryParams: {
          ...payload.queryParams,
        },
      });
    });
  }

  @Action(RefreshNavigation)
  refreshNavigation(
    ctx: StateContext<NavigationStateModel>,
    payload: RefreshNavigation
  ) {
    if (payload.webshopUuid) {
      this.zone.run(() => {
        this.router.navigate([
          BASE_PATH,
          payload.webshopUuid,
          ctx.getState().path,
        ]);
      });
      return;
    }

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    this.zone.run(() => {
      this.router.navigate([BASE_PATH, webshopUuid, ctx.getState().path]);
    });
  }
}
