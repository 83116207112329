import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { StockLevelHistory } from 'src/app/shared/models/stock-level/history.model';

import { StockLevelHistory as StockLevelHistoryV1 } from './model/stock-history.model';
import { StockHistoryAdapter } from './stock-history.adapter';

@Injectable({ providedIn: 'root' })
export class StockLevelService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private fetch(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshopLegacy',
      'v1',
      'stockHistory',
      replacements
    );

    return this.http.get<StockLevelHistoryV1>(endpoint);
  }

  fetchStockHistory(webshopId: number): Observable<StockLevelHistory> {
    const replacements: UrlReplacement[] = [{ name: 'id', value: webshopId }];

    return this.fetch(replacements).pipe(
      catchError(() => {
        return of([
          { type: 'stocklevelhistories', historyData: [null, null, null] },
        ]);
      }),
      switchMap(data => {
        return of(new StockHistoryAdapter().transform(data));
      })
    );
  }
}
