import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadWebshops } from 'src/app/core/actions/webshop.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';

@Component({
  selector: 'app-webshop-tab-all',
  templateUrl: './webshop-tab-all.component.html',
  styleUrls: ['./webshop-tab-all.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopTabAllComponent implements OnInit, OnDestroy {
  @Output()
  selectedWebshopChanged: EventEmitter<Webshop> = new EventEmitter();

  loadingSubject$: Subject<boolean> = new BehaviorSubject(true);

  dataSourceSubject$: Subject<Webshop[]> = new ReplaySubject();

  filterSubject$: Subject<string> = new ReplaySubject();

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.webshops)
      .pipe(takeUntil(this.destroy$))
      .subscribe(webshops => {
        this.dataSourceSubject$.next(webshops);
      });

    this.actions
      .pipe(ofActionCompleted(LoadWebshops), takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadingSubject$.next(false);
      });
  }

  selectedWebshop(webshop: Webshop) {
    this.selectedWebshopChanged.emit(webshop);
  }

  ngOnDestroy(): void {
    this.loadingSubject$.complete();
    this.dataSourceSubject$.complete();
    this.filterSubject$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  onWebshopFilter(filter: string) {
    this.filterSubject$.next(filter);
  }
}
