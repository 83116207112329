import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SetColorTheme } from '../actions/app.action';
import { LoadAccountSettings } from '../actions/settings.action';

import { AuthenticationService } from '../services/authentication.service';
import { AccountSettingsState } from '../states/account-settings.state';
import { AccountState } from '../states/account.state';

/**
 * Settings resolver to be used on the routing declaration.
 * This resolver will load the account settings for the front-end before the
 * user can iteract with it and after the user has been authenticated. Check
 * app-routing module to see where it is used.
 */
@Injectable()
export class SettingsResolver {
  constructor(
    private store: Store,
    private auth: AuthenticationService
  ) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    return this.auth.isAuthenticated().pipe(
      filter(authenticated => authenticated === true),
      concatMap(() => this.auth.isUser()),
      filter(user => user !== null),
      switchMap(() => this.store.select(AccountState.userUuid)),
      filter(userUuid => !!userUuid),
      first(),
      tap(() =>
        window.rudderanalytics.identify('hashed_user_id', {
          name: this.auth.user.name,
          email: this.auth.user.email,
          uuid: this.auth.user.id,
          is_prod_env: environment.production,
        })
      ),
      switchMap((userUUID: string) => {
        return this.store.dispatch(new LoadAccountSettings(userUUID));
      }),
      concatMap(() => this.store.dispatch(new SetColorTheme())),
      map(() => this.store.selectSnapshot(AccountSettingsState.lastWebshop))
    );
  }
}

@Injectable()
export class SettingsLocaleResolver {
  constructor(
    private store: Store,
    private auth: AuthenticationService
  ) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    return this.auth.isAuthenticated().pipe(
      filter(authenticated => authenticated === true),
      concatMap(() => this.auth.isUser()),
      filter(user => user !== null),
      switchMap(() => this.store.select(AccountState.userUuid)),
      filter(userUuid => userUuid !== null),
      first(),
      switchMap((userUUID: string) => {
        return this.store.dispatch(new LoadAccountSettings(userUUID));
      }),
      map(() => this.store.selectSnapshot(AccountSettingsState.localeId))
    );
  }
}
