import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  Subject,
} from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { environment } from 'src/environments/environment';
import { WebshopDialogNextClicked } from './actions/webshop-dialog.action';
import { WebshopsDialogComponent } from './components/webshops-dialog/webshops-dialog.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-webshops',
  templateUrl: './webshops.component.html',
  styleUrls: ['./webshops.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopsComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();

  dialogRef: DialogRef<WebshopsDialogComponent, any>;

  constructor(
    private dialog: Dialog,
    private actions: Actions,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private window: Window,
    @Inject(LOCALE_ID) protected currentLocale
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(
        ofActionDispatched(WebshopDialogNextClicked),
        takeUntil(this.destroy$)
      )
      .subscribe((event: WebshopDialogNextClicked) => {
        this.dialogRef.close();
        this.router.navigate(['app', event.webshop.uuid]);
      });
  }

  ngAfterViewInit(): void {
    this.store
      .selectOnce(AccountSettingsState.localeId)
      .pipe(
        map(localeID => {
          return this.handleLocaleChange(localeID);
        }),
        switchMap((didSwitchLocaleID: boolean) => {
          return this.handleLocaleSwitch(didSwitchLocaleID);
        }),
        this._detectSkipAutoSelection(),
        this._handleWebshopSelectionSkip(),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleLocaleChange(localeID: string): boolean {
    if (localeID === this.currentLocale) {
      return false;
    } else {
      window.location.href = environment.base_path + localeID + '/';
      return true;
    }
  }

  handleLocaleSwitch(_didSwitchLocaleID: boolean): Observable<string> {
    return this.store.selectOnce(AccountSettingsState.lastWebshop);
  }

  /**
   * Processes the last webshop snapshot to see if the dialog should appear
   * or should be skipped.
   */
  _handleWebshopSelectionSkip(): MonoTypeOperatorFunction<string> {
    return tap((lastWebshop: string) => {
      if (lastWebshop != null && lastWebshop.length > 0) {
        this.router.navigate(['app', lastWebshop]);
      } else {
        this.openDialog();
      }
    });
  }

  /**
   * Pipe that detects the auto skip selection parameter to avoid looping
   * between the dashboard with an invalid webshop and the single webshop
   * skip feature.
   */
  _detectSkipAutoSelection(): OperatorFunction<string, any> {
    return switchMap((lastWebshop: string) =>
      this.activatedRoute.queryParams.pipe(
        switchMap(params => {
          if (
            params.skipAutoSelection !== 'undefined' &&
            params.skipAutoSelection === 'true'
          ) {
            return of(null);
          }
          return of(lastWebshop);
        })
      )
    );
  }

  async openDialog() {
    this.dialogRef = this.dialog.open(WebshopsDialogComponent, {
      width: '800px',
      disableClose: true,
    });
  }
}
