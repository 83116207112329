import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { concatMap, filter, first, Observable, switchMap, tap } from 'rxjs';
import { LoadAccount } from '../actions/account.action';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AccountResolver {
  constructor(
    private store: Store,
    private auth: AuthenticationService
  ) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    return this.auth.isAuthenticated().pipe(
      filter(authenticated => authenticated === true),
      concatMap(() => this.auth.isUser()),
      filter(user => user !== null),
      first(),
      switchMap(user => this.store.dispatch(new LoadAccount(user.email)))
    );
  }
}
