import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { ResetIssues } from './actions/todo-list-v2.actions';

@Component({
  selector: 'app-todo-list-v2',
  templateUrl: './todo-list-v2.component.html',
  styleUrl: './todo-list-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoListV2Component implements OnDestroy {
  @Select(PermissionQueries.hasPermission(RolesFeatureKeys.PURCHASE))
  hasPurchasePermission$: Observable<boolean>;

  @Select(PermissionQueries.hasPermission(RolesFeatureKeys.DELIVERIES))
  hasDeliveriesPermission$: Observable<boolean>;

  @Select(PermissionQueries.hasPermission(RolesFeatureKeys.PRODUCTS))
  hasProductsPermission$: Observable<boolean>;

  @Select(PermissionQueries.hasPermission(RolesFeatureKeys.SUPPLIERS))
  hasSuppliersPermission$: Observable<boolean>;

  private store = inject(Store);

  ngOnDestroy(): void {
    this.store.dispatch(new ResetIssues());
  }
}
