<div
  [@navAnimation]="(expanded$ | async) ? 'expanded' : 'collapsed'"
  class="nav-bar"
  [class.collapsed]="(expanded$ | async) === false"
  [class.extended]="expanded$ | async"
  [class.closing]="closed$ | async">
  <div class="nav-bar-container">
    <div class="nav-bar-primary-options">
      @for (option of primaryOptions$ | async; track option.route) {
        <app-nav-menu-v2-option
          [option]="option"
          [tooltipDisabled]="expanded$ | async" />
      }
    </div>

    <div class="nav-bar-secondary-options">
      @for (option of secondaryOptions$ | async; track option.route) {
        <app-nav-menu-v2-option
          [option]="option"
          [tooltipDisabled]="expanded$ | async" />
      }
    </div>

    <div class="divider"></div>

    <div class="nav-bar-panel">
      <button
        class="nav-bar-menu-option toggle-expansion-button"
        (click)="onMenuStateChangeClicked()">
        @if (expanded$ | async) {
          <div class="icon-container">
            <mat-icon
              data-intercom-target="Main menu button Expand"
              aria-label="Main menu button Collapse"
              svgIcon="collapse"
              appTooltip="Collapse menu"
              tooltipPosition="after"
              i18n-appTooltip="navbar collapse menu tooltip"></mat-icon>
          </div>
          <span i18n="navbar collapse menu" class="text-muted"
            >Collapse menu</span
          >
        } @else {
          <div class="icon-container">
            <mat-icon
              data-intercom-target="Main menu button Expand"
              aria-label="Main menu button Expand"
              svgIcon="expand"
              appTooltip="Expand menu"
              tooltipPosition="after"
              i18n-appTooltip="navbar expand menu tooltip"></mat-icon>
          </div>
        }
      </button>
    </div>
  </div>
</div>
