import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { DashboardDetails } from 'src/app/shared/models/stock-level/dashboard-cache.model';

import { DashboardCache as DashboardCacheV1 } from './model/dashboard-cache.model';

export class DashboardCacheAdapter
  implements DataAdapter<DashboardCacheV1[], DashboardDetails>
{
  transform(data: DashboardCacheV1[]): DashboardDetails {
    if (data.length === 0) {
      return null;
    }

    const cache: DashboardDetails = {
      inventoryValue: data[0]['0'],
      stockTurnOver: data[0]['2'],
      trend: data[0]['3'],
      seasonalFactor: data[0]['4'],
      revenue: data[0]['1'],
      categoryA: {
        currentServiceLevel: data[0]['6'],
        inventoryValue: data[0]['9'],
        trendPercentage: data[0]['10'],
        stockTurnOver: data[0]['23'],
        revenue: data[0]['7'],
      },
      categoryB: {
        currentServiceLevel: data[0]['12'],
        inventoryValue: data[0]['15'],
        trendPercentage: data[0]['16'],
        stockTurnOver: data[0]['24'],
        revenue: data[0]['13'],
      },
      categoryC: {
        currentServiceLevel: data[0]['18'],
        inventoryValue: data[0]['21'],
        trendPercentage: data[0]['22'],
        stockTurnOver: data[0]['25'],
        revenue: data[0]['19'],
      },
    };

    return cache;
  }
}
