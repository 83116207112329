import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';

import {
  GetAccessTokens,
  InitializeQueryEngine,
} from '../actions/vizzly-dashboard.actions';
import {
  AccessTokens,
  VizzlyDashboardService,
} from 'src/app/core/api/vizzly-dashboard/vizzly-dashboard.service';
import { WebshopState } from 'src/app/core/states/webshop.state';

export interface VizzlyDashboardStateModel {
  loading: boolean;
  dashboardAccessToken: string;
  dataAccessToken: string;
}

@State<VizzlyDashboardStateModel>({
  name: 'vizzlyDashboardState',
  defaults: {
    loading: false,
    dashboardAccessToken: '',
    dataAccessToken: '',
  },
})
@Injectable()
export class VizzlyDashboardState {
  constructor(
    private store: Store,
    private vizzlyDashboardService: VizzlyDashboardService
  ) {}

  @Selector([VizzlyDashboardState])
  static dashboardAccessToken(state: VizzlyDashboardStateModel): string {
    return state.dashboardAccessToken;
  }
  @Selector([VizzlyDashboardState])
  static dataAccessToken(state: VizzlyDashboardStateModel): string {
    return state.dataAccessToken;
  }

  @Action(GetAccessTokens, { cancelUncompleted: true })
  getAccessTokens(ctx: StateContext<VizzlyDashboardStateModel>) {
    return this.vizzlyDashboardService
      .getAccessTokens(this.store.selectSnapshot(WebshopState.selected).uuid)
      .pipe(
        tap((accessTokens: AccessTokens) => {
          ctx.patchState({
            dashboardAccessToken: accessTokens.dashboardAccessToken,
            dataAccessToken: accessTokens.dataAccessToken,
          });
        })
      );
  }

  @Action(InitializeQueryEngine, { cancelUncompleted: true })
  initialize(ctx: StateContext<VizzlyDashboardStateModel>) {
    return this.vizzlyDashboardService.queryEngine();
  }
}
