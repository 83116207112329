import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-todo-data-table',
  templateUrl: './todo-data-table.component.html',
  styleUrl: './todo-data-table.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TodoDataTableComponent {}
