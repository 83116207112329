import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { ToggleIntercom } from 'src/app/core/actions/app.action';
import { LocaleService } from 'src/app/core/services/locale.service';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { AuthUser } from 'src/app/shared/models/auth/auth.models';
import { SafeRedirects } from 'src/app/shared/utils/safe-redirects';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar-help',
  templateUrl: './toolbar-help.component.html',
  styleUrls: ['./toolbar-help.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarHelpComponent
  extends SafeRedirects
  implements OnInit, OnDestroy
{
  user: AuthUser = null;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private window: Window,
    private localeService: LocaleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(AuthenticationState.account)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: AuthUser) => (this.user = user));
  }

  onHelpClicked() {
    this.safeNewTabRedirect(environment.helpUrl[this.localeService.locale]);
  }

  redirectToFeedbackPortal(): void {
    Canny('identify', {
      appID: '621f5626d4c36338280e3bee',
      user: {
        // User DATA
        email: this.user.email,
        name: this.user.name,
        id: this.user.id,
      },
    });

    this.safeNewTabRedirect('https://feedback.optiply.com');
  }

  onCannyClicked() {
    this.safeNewTabRedirect('https://feedback.optiply.com/changelog');
  }

  openIntercom() {
    this.store.dispatch(new ToggleIntercom());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
