import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { STRINGS } from '../../model/todo-list-v2.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { Select, Store } from '@ngxs/store';
import {
  AddFilterParamNoReload,
  RemoveAllFilters,
} from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';
import { SUPPLIERS_FILTERS } from 'src/app/features/suppliers-v2/model/suppliers-v2-data-table.model';
import { NavigateTo } from 'src/app/core/actions/navigation.action';
import { Observable, delay, distinctUntilKeyChanged } from 'rxjs';
import { Issue, IssueStatusColors } from '../../model/todo-list-v2.model';
import { SuppliersTodoQueries } from '../../states/suppliers-todo.queries';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadSuppliersIssues } from '../../actions/todo-list-v2.actions';

@Component({
  selector: 'app-suppliers-todo',
  templateUrl: './suppliers-todo.component.html',
  styleUrl: './suppliers-todo.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuppliersTodoComponent implements OnInit {
  @Select(SuppliersTodoQueries.issuesLoading)
  loading$: Observable<boolean>;

  @Select(SuppliersTodoQueries.issuesData)
  issues$: Observable<Issue[]>;

  @Select(SuppliersTodoQueries.issuesDataAvailable)
  issuesAvailable$: Observable<boolean>;

  readonly ISSUES_STATUS = IssueStatusColors;

  readonly COLUMNS_STRINGS = STRINGS.columns.suppliers;

  readonly TITLE = STRINGS.metadata.todoList.suppliersTitle;

  readonly COMMON_STRINGS = MESSAGES.common;

  private store = inject(Store);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        distinctUntilKeyChanged('uuid'),
        delay(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new LoadSuppliersIssues());
      });
  }

  goToSuppliers(): void {
    this.store.dispatch(new NavigateTo(['suppliers']));
  }

  filterSuppliersIssues(issue: string): void {
    this.store.dispatch([
      new RemoveAllFilters(),
      new AddFilterParamNoReload(SUPPLIERS_FILTERS[issue]),
      new NavigateTo(['suppliers']),
    ]);
  }
}
