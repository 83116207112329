<app-menu>
  <div class="container">
    <div class="title-container">
      <mat-icon svgIcon="status-report" class="toolbar-icon"></mat-icon>
      <span class="container-title">
        @if ((iconColor$ | async) === 'green') {
          {{ MESSAGES.systemOperational }}
        } @else {
          {{ MESSAGES.title }}
        }
      </span>
    </div>

    @if ((iconColor$ | async) !== 'green') {
      @for (
        incident of incidents$ | async;
        track idx;
        let idx = $index;
        let last = $last
      ) {
        <div class="incident-container">
          <app-tag [color]="incident.color" variant="subtle" size="small">{{
            incident.status | titlecase
          }}</app-tag>
          <span class="incident-name">{{ incident.name }}</span>
          <span class="incident-body">{{ incident.body }}</span>
          <span class="incident-date">{{ incident.updated_at | date }}</span>
        </div>

        @if (!last) {
          <div class="divider"></div>
        }
      }
    }
  </div>
  <div class="container-divider"></div>
  <div class="redirect-button-container">
    <span (click)="redirectToStatus()" class="redirect-button">{{
      MESSAGES.goToButton
    }}</span>
  </div>
</app-menu>
