import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from 'src/app/core/modules/app-routing/app-routing.module';
import { WebshopResolver } from 'src/app/core/resolvers/webshop.resolver';
import { MaterialModule } from 'src/app/core/modules/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { NavMenuUserInfoComponent } from './components/nav-menu-user-info/nav-menu-user-info.component';
import { AccountComponent } from './components/toolbar/components/account/account.component';
import { LanguagesComponent } from './components/toolbar/components/languages/languages.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HomeComponent } from './home.component';
import { ToolbarHelpComponent } from './components/toolbar/components/toolbar-help/toolbar-help.component';
import { TooltipModule } from 'src/app/shared/components/design-system/tooltip/tooltip.module';
import { ToggleModule } from 'src/app/shared/components/design-system/toggle/toggle.module';
import { ButtonModule } from 'src/app/shared/components/design-system/button/button.module';
import { MenuModule } from 'src/app/shared/components/design-system/menu/menu.module';
import { StatusPageComponent } from './components/toolbar/components/status-page/status-page.component';
import { TagsModule } from 'src/app/shared/components/design-system/tags/tags.module';
import { StatusPageDropdownComponent } from './components/toolbar/components/status-page/components/status-page-dropdown/status-page-dropdown.component';
import { NavMenuV2Component } from './components/nav-menu-v2/nav-menu-v2.component';
import { NavMenuV2OptionComponent } from './components/nav-menu-v2/components/nav-menu-v2-option/nav-menu-v2-option.component';
import { WebshopSelectorV2Component } from './components/toolbar/components/webshop-selector-v2/webshop-selector-v2.component';
import { OverlayContainerV2Module } from 'src/app/shared/components/design-system/overlay-container-v2/overlay-container-v2.module';

@NgModule({
  declarations: [
    HomeComponent,
    ToolbarComponent,
    NavMenuUserInfoComponent,
    LanguagesComponent,
    AccountComponent,
    ToolbarHelpComponent,
    StatusPageComponent,
    StatusPageDropdownComponent,
    NavMenuV2Component,
    NavMenuV2OptionComponent,
    WebshopSelectorV2Component,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    AppRoutingModule,
    TooltipModule,
    ToggleModule,
    ButtonModule,
    MenuModule,
    TagsModule,
    OverlayContainerV2Module,
  ],
  exports: [HomeComponent],
  providers: [WebshopResolver, { provide: Window, useValue: window }],
})
export class HomeModule {}
