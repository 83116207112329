<div [ngClass]="baseClasses()">
  <div class="card-title">
    <!-- This can be used for title icons -->
    <ng-content select="[cardTitleIcon]" />

    <span>{{ cardTitle() }}</span>

    <ng-content select="[cardTitleTooltip]" />
  </div>

  <div [ngClass]="contentClasses()">
    <ng-content />

    <!-- This can be used to add some additional information -->
    <ng-content select="[cardExtras]" />
  </div>
</div>
