import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { STRINGS } from '../../model/webshop-metrics.strings';

@Component({
  selector: 'app-webshop-metrics-difference',
  templateUrl: './webshop-metrics-difference.component.html',
  styleUrl: './webshop-metrics-difference.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopMetricsDifferenceComponent {
  previousValue = input.required<number>();

  difference = input.required<number>();

  differencePositiveFormat = computed(() => Math.abs(this.difference()));

  differenceIcon = computed(() => {
    if (this.difference() === 0) return 'minus';

    return this.difference() > 0 ? 'arrow-up-right' : 'arrow-down-right';
  });

  differenceClass = computed(() => {
    if (this.difference() === 0) return 'neutral';

    return this.difference() > 0 ? 'positive' : 'negative';
  });

  readonly TOOLTIPS = STRINGS.tooltips;
}
