import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CardInfoAlign } from './model/info-card.model';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {
  cardTitle = input.required<string>();

  alignment = input<CardInfoAlign>('left');

  baseClasses = computed(() => ['card', `card-${this.alignment()}`]);

  contentClasses = computed(() => [
    'card-content',
    `card-content-${this.alignment()}`,
  ]);
}
