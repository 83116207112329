import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MenuOption } from '../../model/nav-menu-v2.model';

@Component({
  selector: 'app-nav-menu-v2-option',
  templateUrl: './nav-menu-v2-option.component.html',
  styleUrl: './nav-menu-v2-option.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavMenuV2OptionComponent {
  @Input({ required: true })
  option: MenuOption;

  @Input()
  tooltipDisabled: boolean;
}
