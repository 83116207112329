import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/modules/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { Auth0LoginComponent } from './components/auth0-login/auth0-login.component';
import { SignInAuthenticatedComponent } from './components/sign-in-authenticated/sign-in-authenticated.component';
import { SsoComponent } from './sso.component';

@NgModule({
  declarations: [
    SsoComponent,
    Auth0LoginComponent,
    SignInAuthenticatedComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, SharedModule],
  exports: [SsoComponent],
})
export class SsoModule {}
