import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedWebshopUpdated } from 'src/app/core/actions/settings.action';
import { LoadWebshops } from 'src/app/core/actions/webshop.action';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { WebshopDialogNextClicked } from '../../actions/webshop-dialog.action';

@Component({
  selector: 'app-webshops-dialog',
  templateUrl: './webshops-dialog.component.html',
  styleUrls: ['./webshops-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopsDialogComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private authService: AuthenticationService
  ) {}

  selectedWebshop: Webshop = null;

  webshopSelected = false;

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.store.dispatch(new LoadWebshops());
  }

  selectedWebshopChanged(webshop: Webshop) {
    this.webshopSelected = webshop !== null;
    this.selectedWebshop = webshop;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNextClicked(_event: any) {
    this.store
      .dispatch(new LastSelectedWebshopUpdated(this.selectedWebshop.uuid))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new WebshopDialogNextClicked(this.selectedWebshop));
      });
  }

  onLogoutClicked(_event: any) {
    this.authService.logout();
  }
}
