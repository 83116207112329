<div class="home-container">
  <app-toolbar class="toolbar"></app-toolbar>

  <div class="home-content-section">
    <app-nav-menu-v2 class="nav-menu-container" />

    <div
      class="content-container"
      [class.collapsed]="(expanded$ | async) === false"
      [class.extended]="expanded$ | async"
      [class.closed]="closed$ | async">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
